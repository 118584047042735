
import { BondMasterData, DebtRayRanking } from '../bond/BasicKpis';


export const RecentIssue = ({ data }) => {
    const recent = data.reduce((max, current) => (max.firstAnnouncementDate > current.firstAnnouncementDate ? max : current));
    return (
        <div className='row-container' >
            <BondMasterData data={recent} flex={'1 1 0'} />
            <DebtRayRanking data={recent} flex={'2 1 0'} />
        </div>)
}