export function getCookie(name) {
    // Split the document.cookie string into individual cookies
    var cookies = document.cookie.split(';');

    // Iterate through the cookies to find the one with the specified name
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Check if the cookie starts with the specified name
        if (cookie.startsWith(name + '=')) {
            // Extract and return the cookie value
            return cookie.substring(name.length + 1);
        }
    }
    // Return null if the cookie is not found
    return null;
}

export const getSettingsFromCookie = () => {
    const cookie = getCookie('settings')
    if (cookie === null) {
        throw new Error ("Cannot read user settings")
    }
    return JSON.parse(atob(cookie))
}