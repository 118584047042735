import { useTheme } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import { defaults } from 'chart.js';

Chart.register(
    annotationPlugin,
    ArcElement,
    BarElement,
    CategoryScale,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
    ChartDataLabels,
)
const RankHistogramPlot = (props) => {
    const { x, y, nBonds, color, useRankColor } = props
    const theme = useTheme()
    const rankColors = Array.from({ length: 5 }, (_, index) => theme.palette.ranks[index + 1])
    defaults.font.size = 12
    defaults.font.family = theme.typography.fontFamily
    const options = {
        indexAxis: 'x', // flip bars
        maintainAspectRatio: false,
        animation: false,
        scales: {
            x: {
                display: true,
                title: {
                    display: false,
                    text: x.label,
                    color: color,
                    font: {
                        size: 12,
                    },
                },
                grid: {
                    display: false,
                },
                ticks: {
                    color: color,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                display: true,
                suggestedMax: 50, // set max of y axis for comparison + labels to be visisble
                border: { display: true },
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                    color: theme.palette.secondary.main,
                    font: {
                        size: 12,
                        weight: 'medium',
                    },
                },
                title: {
                    display: true,
                    text: y.label,
                    color: color,
                    font: {
                        size: 12,
                    },
                },
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                color: theme.palette.secondary.main,
                font: {
                  size: 12,
                },
                formatter: (value, context) => {
                    return parseFloat(value).toFixed(0);
                },
                offset: 0,
            },
            tooltip: {
                backgroundColor: '#FFFFFF',
                displayColors: false,
                titleColor: theme.palette.secondary.dark,
                bodyColor: theme.palette.secondary.light,
                borderWidth: 2,
                borderColor: theme.palette.secondary.veryLight,
                caretSize: 0, // no tooltip arrow
                caretPadding: 10,
                callbacks: {
                    label: function (context) {
                        return `${nBonds[context.dataIndex]} Bonds (${context.parsed.y.toFixed(0)}%)`;
                    },
                    title: function (tooltipItems) {
                        return `Rank: ${tooltipItems[0].label}`;
                    },
                },

            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };


    const chart_data = {
        labels: x.values,
        datasets: [
            {
                label: y.label,
                data: y.values,
                barThickness: 32,
                backgroundColor: useRankColor ? rankColors : color,
                hoverBackgroundColor: theme.palette.highlight.main,
                borderRadius: 6,
            },
        ],
    };
    return (<Bar options={options} data={chart_data} />)
}

export default RankHistogramPlot