import { ListItemText} from "@mui/material";
import Search from '../../components/Search';


export default function SearchBond({ candidates, onSearchResultClick }) {
    const ShowResultItem = ({ result }) => {
        return (
            <ListItemText
                primary={result.issuer}
                secondary={<>{result.isin}<br />{result.firstAnnouncementDate}</>} />
        )
    }
    return (
        <Search
            candidates={candidates}
            onSearchResultClick={onSearchResultClick}
            searchFields={['isin', 'issuer']}
            id={'isin'}
            ShowResultItem={ShowResultItem}
            minHeight={80}
        />
    )

}



