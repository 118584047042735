import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
import { useSharedIsin } from '../../contexts/SharedIsin';
import SearchCompany from './SearchCompany';
import CompanyTable from './CompanyTable';
import PageTitle from '../../components/headers/PageTitle';
import { IdTitle } from '../../components/headers/IdTitle';
import { SectionTitle } from '../../components/headers/SectionTitle';
import { CompanySummary } from './CompanySummary';
import { RecentIssue } from './RecentIssue';
import fetchWithRefresh from '../../helpers/api';
import useHandleError from '../../helpers/handleError';


export default function Company({ defaultCompany }) {
    const { isin, setIsin } = useSharedIsin();
    const [company, setCompany] = useState(defaultCompany);
    const [data, setData] = useState(undefined);
    const [searchCandidates, setSearchCandidates] = useState([]);
    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const handleError = useHandleError()


    useEffect(() => {
        fetchWithRefresh('/api/bond_ranking/search/company')
            .then((resp) => resp.json())
            .then(setSearchCandidates)
            .catch((error) => {
                console.log("Fetch error company search", error)
                setError(error)
            })
    }, [])


    useEffect(() => {
        const fetchData = () => {
            fetchWithRefresh(`/api/bond_ranking/company/?company=${encodeURIComponent(company)}`)
                .then((resp) => resp.json())
                .then(setData)
                .catch((error) => {
                    console.log("Fetch error company", error)
                    setError(error)
                })
        };
        if (![null, undefined].includes(company)) {
            fetchData()
        }
    }, [company])


    const handleRowClick = (isin) => {
        setIsin(isin)
        navigate("/bond/bond-ranking")
    }

    handleError(error)

    return (
        <div className='page-container'>
            <Box display={'flex'} justifyContent={'space-between'}>
                <PageTitle title={'IssuerView'} />
                {searchCandidates && <SearchCompany candidates={searchCandidates} onSearchResultClick={setCompany} />}
            </Box>

            {data &&
                <>
                    <IdTitle id={data.id} />
                    <Box className='row-container' marginBottom={'24px'} >
                        <CompanySummary title={'Company Statistics'} data={data.statistics} flex={'1 1 0'} />
                        <Box flex={'2 1 0'}></Box>
                    </Box>
                    <CompanyTable data={data.data} onRowClick={handleRowClick} />
                    <SectionTitle title={'Most Recent Issue'} />
                    <RecentIssue data={data.data} />
                    <Box padding='32px'></Box>
                </>
            }
        </div>
    )
}