import * as React from 'react';
import BoxTitle from '../../components/headers/BoxTitle';
import GraphBox from '../../components/GraphBox';
import { InfoTable } from '../../components/tables/InfoTable';
import { roundWithNull } from '../../helpers/handleNull';


const format = (s) => { return s.toLocaleString('en-US', { maximumFractionDigits: 0, useGrouping: true }) }



export const CompanySummary = ({ data, title, flex }) => {
    const rows = [
        { key: 'Number of Bonds', value: data.numberOfBonds},
        { key: 'Total Volume', value: `${format(data.totalVolume)} m` },
        { key: 'Coupon Range', value: `${data.couponMin} -  ${data.couponMax} %` },
        { key: 'Life Span Range', value: `${data.lifeSpanMin} -  ${data.lifeSpanMax} years` },
        { key: 'Avg. BondRank', value: `${roundWithNull(data.avgOverallRank, 1)}` },
        { key: 'Avg. PricingRank', value: `${roundWithNull(data.avgPricingRank, 1)}` },
        { key: 'Avg. TimingRank', value: `${roundWithNull(data.avgTimingRank, 1)}` },
    ]
    return (
        <GraphBox paddingBottom='16px' flex={flex}>
            <BoxTitle title={title} paddingBottom='16px'/>
            <InfoTable rows={rows} />
        </GraphBox>)
}

