import { ListItemText } from "@mui/material";
import Search from '../../components/Search';


export default function SearchBank({ candidates, onSearchResultClick }) {
    const ShowResultItem = ({ result }) => {return <ListItemText primary={result.syndicate}/>}
    return (
        <Search
            candidates={candidates}
            onSearchResultClick={onSearchResultClick}
            searchFields={['syndicate']}
            id={'syndicate'}
            ShowResultItem={ShowResultItem}
        />
    )

}