import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';


export default function BoxTitle({ title, icon, paddingBottom = '0px' }) {
    return (
        <Box display="flex"
            alignItems="center"
            gap='8px'
            alignSelf='stretch'
            paddingBottom={paddingBottom}>
            {icon}
            <Typography fontSize="16px" fontWeight='600' lineHeight='24px' color='#101828'>
                {title}
            </Typography>
        </Box>
    )

}

