import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataTable } from '../components/tables/DataTable';
import EditUser from './EditUser';
import { GetRegistrationLinkUserTable } from './RegistrationLink'
import fetchWithRefresh from '../helpers/api';


const fetchUsers = async (setUsers) => {
    fetchWithRefresh('/api/auth/users')
        .then((r) => r.json())
        .then(setUsers)
        .catch((error) => console.log(error))
}

export default function UserTable({ dbChanged, setDbChanged }) {
    const [users, setUsers] = useState([])
    const centered = { align: 'center', headerAlign: 'center' }
    const handleDbChanged = (x) => { setDbChanged(x) }
    const columns = [
        { field: 'id', headerName: 'ID', maxWidth: 100, flex: 1, display: false },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'surname', headerName: 'Surname', flex: 1 },
        { field: 'company', headerName: 'Company', flex: 1, display: false },
        { field: 'email', headerName: 'Email', minWidth: 300, flex: 1 },
        { field: 'roles', headerName: 'Role', flex: 1 },
        { field: 'active', headerName: 'Active', flex: 1 },
        { field: 'one_time_password', headerName: 'Registration Password', flex: 1, display: false },
        { field: 'one_time_password_expiry', headerName: 'Registration Expiry', flex: 1, display: false },
        { field: 'created_at', headerName: 'Created at', flex: 1, display: false },
        {
            field: 'registration_link',
            headerName: 'Registration',
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: GetRegistrationLinkUserTable,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            flex: 1,
            sortable: false,
            ...centered,
            getActions: (params) => [
                <EditUser id={params.id} setDbChanged={handleDbChanged} />
            ]
        },

    ];

    useEffect(() => {
        if (dbChanged) {
            fetchUsers(setUsers)
        }
        setDbChanged(false)
    }, [dbChanged, setDbChanged])

    return (
        <>
            <DataTable
                title={'Users'}
                columns={columns}
                data={users}
                onRowClick={() => { return null }}
                unit={'Users'}
                initialSorting={{ field: 'created_at', sort: 'desc' }} />
        </>
    )
}