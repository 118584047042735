import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as React from "react";

export default function SelectDates(props) {
    const { minDate, maxDate, setMinDate, setMaxDate, minLimit, maxLimit } = props

    const onMinDayChange = (newDate) => {
        const firstDayOfMonth = newDate.startOf('month');
        setMinDate(firstDayOfMonth.format("YYYY-MM-DD"));
    }

    const onMaxDayChange = (newDate) => {
        const lastDayOfMonth = newDate.endOf('month');
        setMaxDate(lastDayOfMonth.format("YYYY-MM-DD"));
    }


    return (
        <>
            <Box display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft='80px'
                paddingRight='80px'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['year', 'month']}
                        value={minDate === undefined ? dayjs(minLimit) : dayjs(minDate)}
                        minDate={dayjs(minLimit)}
                        maxDate={dayjs(maxLimit)}
                        onChange={onMinDayChange}
                        sx={{ width: '40%' }}
                    />
                    <div className="range-bar"></div>
                    <DatePicker
                        views={['year', 'month']}
                        value={maxDate === undefined ? dayjs(maxLimit) : dayjs(maxDate)}
                        minDate={dayjs(minLimit)}
                        maxDate={dayjs(maxLimit)}
                        onChange={onMaxDayChange}
                        sx={{ width: '40%' }}

                    />
                </LocalizationProvider>
            </Box>
        </>
    )
}
