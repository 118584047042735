export const percentileThresholds = [0, 0.075, 0.2, 0.4, 0.5, 0.6, 0.7, 0.875, 0.95, 1]

export const rankPercentileThreshold = [
    { rank: 5, low: percentileThresholds[0], high: percentileThresholds[1] },
    { rank: 4, low: percentileThresholds[1], high: percentileThresholds[2] },
    { rank: 3, low: percentileThresholds[2], high: percentileThresholds[3] },
    { rank: 2, low: percentileThresholds[3], high: percentileThresholds[4] },
    { rank: 1, low: percentileThresholds[4], high: percentileThresholds[5] },
    { rank: 2, low: percentileThresholds[5], high: percentileThresholds[6] },
    { rank: 3, low: percentileThresholds[6], high: percentileThresholds[7] },
    { rank: 4, low: percentileThresholds[7], high: percentileThresholds[8] },
    { rank: 5, low: percentileThresholds[8], high: percentileThresholds[9] },]


export function computeQuantiles(data, percentiles, interpolation = 'lower') {
    let sortedData = data.slice().sort((a, b) => a - b);
    let quantiles = percentiles.map(p => {
        let index = (sortedData.length - 1) * p;
        let lowerIndex = Math.floor(index);
        let upperIndex = Math.ceil(index);

        if (lowerIndex === upperIndex) {
            return sortedData[lowerIndex];
        } else {
            let lowerValue = sortedData[lowerIndex];
            if (interpolation === 'lower') { return lowerValue }
            if (interpolation === 'linear') {
                let upperValue = sortedData[upperIndex];
                return lowerValue + (upperValue - lowerValue) * (index - lowerIndex);
            }
            throw new Error ("Unknown quantile interpolation")
        }
    });

    return quantiles;
}

export function linearInterpolation(x, y, xValue) {
    // Find the two closest x values in the array
    if (xValue <= x[0]) {
        return y[0];
    }
    if (xValue >= x[x.length - 1]) {
        return y[y.length - 1];
    }

    let i = 1;
    while (x[i] < xValue) {
        i++;
    }

    // Perform linear interpolation
    const x0 = x[i - 1];
    const x1 = x[i];
    const y0 = y[i - 1];
    const y1 = y[i];
    const interpolatedY = y0 + (y1 - y0) * (xValue - x0) / (x1 - x0);
    return interpolatedY;
}