import Box from "@mui/material/Box";
import * as React from "react";
import { useState } from "react";
import LeagueTable from "./LeagueTable.jsx";
import RankHistogramPlot from "../../components/plots/RankHistogramPlot.jsx";
import HistogramPlot from "../../components/plots/HistogramPlot.jsx";
import { ParentSize } from "@visx/responsive";
import AverageRankByVolume from "../../components/plots/AverageRankByVolumePlot.jsx"
import Switch from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import { getIndices, sum, slice } from '../../helpers/arrayHelpers.js';
import { useTheme } from '@mui/material/styles';
import GraphBox from '../../components/GraphBox.jsx';
import BoxTitle from '../../components/headers/BoxTitle.jsx';
import PricingCriticalLossPlot from '../../components/plots/PricingCriticalLoss.jsx';
import TimingCriticalLossPlot from "../../components/plots/TimingCriticalLoss.jsx";
import { SectionTitle } from "../../components/headers/SectionTitle.jsx";
import LossCurvePlot from "../../components/plots/LossCurve.jsx";


const LossPerBond = (props) => {
    const { field, data, onClick, title, height = '450px', width } = props
    return (
        <GraphBox height={height} >
            <BoxTitle title={title} paddingBottom={'40px'} />
            <div style={{ minHeight: 0, flex: 1 }}>
                <ParentSize>
                    {({ width, height }) => <LossCurvePlot
                        raw_data={data}
                        field={field}
                        onClick={onClick}
                        width={width}
                        height={height} />}
                </ParentSize>
            </div>
        </GraphBox>
    )
}

const RankDistribution = (props) => {
    const { x, y, nBonds, title, color, useRankColor, width = 376, height = 330, flex } = props
    const xData = {
        label: 'Rank',
        values: x
    }
    const yData = {
        label: '%',
        values: y,
    }
    return (
        <GraphBox
            width={width}
            height={height}
            flex={flex}>
            <BoxTitle title={title} />
            <Box flex={1} paddingTop={'48px'} >
                <RankHistogramPlot x={xData} y={yData} nBonds={nBonds} color={color} useRankColor={useRankColor} />
            </Box>
        </GraphBox>
    );
}


const Histogram = (props) => {
    const { x, y, title, yLabel = 'Number of bonds', width = 376, height = 528, flex } = props
    const xValue = {
        label: title,
        values: x
    }
    const yValue = {
        label: yLabel,
        values: y
    }
    return (
        <GraphBox width={width} height={height} flex={flex}>
            <BoxTitle title={title} paddingBottom={'40px'} />
            <Box flex={'1'}>
                <HistogramPlot x={xValue} y={yValue} />
            </Box>
        </GraphBox>
    );

}


const RankMeanByVolume = (props) => {
    const { data, title, height = '300px' } = props
    return (
        <GraphBox height={height}>
            <BoxTitle title={title} paddingBottom={'40px'} />
            <div style={{ minHeight: 0, flex: 1, display: 'flex' }}>
                <ParentSize>
                    {({ width, height }) => <AverageRankByVolume
                        data={data}
                        width={width}
                        height={height} />}
                </ParentSize>
            </div>
        </GraphBox>
    )
        ;
}


const PricingOutliers = (props) => {
    // Ignore possible cancellation of signs (e.g. timing rank of left radical rank 2 cold have  issuer gain. Markus 8.9.2023 
    const { title, data, height = '300px' } = props
    const [showAverage, setShowAverage] = useState(false)
    const handleChange = (event) => {
        setShowAverage(event.target.checked)
    }
    const pricing = data.pricingLoss
    const pricing_indices = getIndices(pricing.rank, [4, 5])

    const issuerLossTotal = sum(slice(pricing.lossNegative, pricing_indices))
    const issuerLossNBonds = sum(slice(pricing.numberOfBondsLossNegative, pricing_indices))
    const investorLossTotal = - sum(slice(pricing.lossPositive, pricing_indices))
    const investorLossNBonds = sum(slice(pricing.numberOfBondsLossPositive, pricing_indices))
    const issuerLoss = showAverage ? issuerLossTotal / issuerLossNBonds : issuerLossTotal
    const investorLoss = showAverage ? investorLossTotal / investorLossNBonds : investorLossTotal

    return (
        <GraphBox height={height} >
            <Box display="flex" justifyContent={'space-between'} paddingBottom={'0px'}>
                <BoxTitle title={title} />
                <FormControlLabel
                    control={<Switch
                        className="switch"
                        checked={showAverage}
                        onChange={handleChange} />}
                    label='Average per Issue'
                />
            </Box>
            <div style={{ minHeight: 0, flex: 1, display: 'flex', justifyContent: 'space-evenly' }}>
                <PricingCriticalLossPlot
                    width={250}
                    height={280}
                    amount={issuerLoss}
                    title={'Pricing Loss for Issuer'}
                    highlightRadical={'left'} />
                <PricingCriticalLossPlot
                    width={250}
                    height={280}
                    amount={investorLoss}
                    title={'Pricing Loss for Investor'}
                    highlightRadical={'right'} />
            </div>
        </GraphBox>
    )
}

const TimingOutliers = (props) => {
    // Ignore possible cancellation of signs (e.g. timing rank of 2 cold have negative timing gain). Markus 8.9.2023 
    const { title, data, height = '300px' } = props
    const [showAverage, setShowAverage] = useState(false)
    const handleChange = (event) => {
        setShowAverage(event.target.checked)
    }
    const timing = data.timingGain
    const loss_indices = getIndices(timing.rank, [4, 5])
    const gain_indices = getIndices(timing.rank, [1, 2])
    const lossTotal = sum(slice(timing.gainNegative, loss_indices))
    const lossNBonds = sum(slice(timing.numberOfBondsGainNegative, loss_indices))
    const gainTotal = sum(slice(timing.gainPositive, gain_indices))
    const gainNBonds = sum(slice(timing.numberOfBondsGainPositive, gain_indices))
    const loss = showAverage ? lossTotal / lossNBonds : lossTotal
    const gain = showAverage ? gainTotal / gainNBonds : gainTotal
    return (
        <GraphBox height={height} >
            <Box display="flex" justifyContent={'space-between'} paddingBottom={'20px'}>
                <BoxTitle title={title} />
                <FormControlLabel
                    control={<Switch className="switch" checked={showAverage} onChange={handleChange} />}
                    label='Average per Issue'
                />
            </Box>
            <div style={{ minHeight: 0, flex: '1' }}>
                <ParentSize>
                    {({ width, height }) => <TimingCriticalLossPlot
                        width={width}
                        height={height}
                        loss={loss}
                        gain={gain} />}
                </ParentSize>
            </div>
        </GraphBox>
    )
}


export const Statistics = (props) => {
    const { data, onRowClick } = props
    const theme = useTheme()
    if (data) {
        return (
            <>
                <SectionTitle title={'League Statistics'} />

                {/* Pricing Loss and Timing Gain by issuer sorted*/}
                <Box className='row-container' marginBottom={'24px'}>
                    <LossPerBond data={data.leagueData}
                        field={'pricingLossForIssuer'}
                        onClick={onRowClick}
                        title={"Pricing Early Secondary Market (m)"} />
                    <LossPerBond data={data.leagueData}
                        field={'timingAverageGain'}
                        onClick={onRowClick}
                        title={"Timing Gains and Losses (m)"} />
                </Box>

                {/* Rank Statistics*/}
                <Box className='row-container' marginBottom={'24px'}>
                    <RankDistribution x={data.statistics.rank.rank}
                        y={data.statistics.rank.overallRankPercent}
                        nBonds={data.statistics.rank.overallRankCount}
                        title={'BondRank Distribution'}
                        color={theme.palette.primary[700]}
                        useRankColor={true} />
                    <RankDistribution x={data.statistics.rank.rank}
                        y={data.statistics.rank.pricingRankPercent}
                        nBonds={data.statistics.rank.pricingRankCount}
                        title={'PricingRank Distribution'}
                        color={theme.palette.primary[300]}
                        useRankColor={true} />
                    <RankDistribution x={data.statistics.rank.rank}
                        y={data.statistics.rank.timingRankPercent}
                        nBonds={data.statistics.rank.timingRankCount}
                        title={'TimingRank Distribution'}
                        color={theme.palette.primary[500]}
                        useRankColor={true} />
                </Box>

                {/* Ranks by volume */}
                <Box className='row-container' marginBottom={'24px'}>
                    <RankMeanByVolume
                        data={data.statistics.averageRankByAmountIssued}
                        title={'Average Rank by Volume (m)'} />
                </Box>

                {/* Outliers */}
                <Box className='row-container' marginBottom={'24px'}>
                    <PricingOutliers
                        title={'Pricing Outliers (m)'}
                        data={data.statistics} />
                    <TimingOutliers
                        title={'Timing Gains and Losses (m)'}
                        data={data.statistics} />
                </Box>

                {/* Histograms */}
                <Box className='row-container' marginBottom={'72px'}>
                    <Histogram x={data.statistics.pricing.midpoints}
                        y={data.statistics.pricing.histogram}
                        title={'Pricing Distribution (m)'}
                        flex={'1'} />
                    <Histogram x={data.statistics.deltaSpread.midpoints}
                        y={data.statistics.deltaSpread.histogram}
                        title={'Distribution Early Secondary Market Development (bps)'}
                        flex={'1'} />
                    <Histogram x={data.statistics.timing.midpoints}
                        y={data.statistics.timing.histogram}
                        title={'Timing Gains and Losses (m)'}
                        flex={'1'} />
                </Box>
            </>
        )
    }
}

