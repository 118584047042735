import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import {  useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';


export default function InfoPage({ title, redirectToLogin = false }) {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/')
    }

    const handleLoginRedirect = () => {
        navigate('/login');
    };


    return (
        <div style={{
            backgroundImage: "url('../public/background/mesh-background.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100vh'

        }}>
            <Box padding='60px' alignSelf='start'>
                <img src="/public/logos/logo_dark_mode.svg"
                    alt="DebtRay"
                    className="logo"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                />
            </Box>

            <Box display="flex"
                justifyContent='center'
                flexDirection={'column'}
                alignItems={'center'}>
                <Typography sx={{ fontSize: "30px", color: '#fff' }}>
                    {title}
                </Typography>
                {
                    redirectToLogin &&
                    <Typography sx={{ fontSize: "20px", color: '#fff', mt:'20px' }}>
                        <Link onClick={handleLoginRedirect} style={{ cursor: 'pointer', color: 'white' }}>Login</Link>
                    </Typography>
                }
            </Box>
        </div>
    )
}