import { useNavigate } from 'react-router-dom';
import { RefreshError } from './RefreshError';

export default function useHandleError() {
    const navigate = useNavigate()
    return (error) => {
        if (error instanceof RefreshError) {
            navigate('/session-expired')
        }
    }
}