import * as React from "react";
import { Typography } from '@mui/material';


export default function FilterSummary ({ data })  {
    if (data) {
        const summary = data.leagueSummary
        if (summary.nBonds === 0) {
            return (<Typography >
                No bonds found
            </Typography>)    
        }
        const minDate = summary.minDate.split('-')
        const maxDate = summary.maxDate.split('-')
        return (<Typography >
            {summary.nBonds} Selected Bonds ({minDate[1]}/{minDate[0]} - {maxDate[1]}/{maxDate[0]})
        </Typography>)
    }
}