import * as React from "react";
import { FilterList } from "@mui/icons-material";
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function FilterLeagueButton({ onClick }) {
    const theme = useTheme()
    return (
        <Button
            onClick={onClick}
            startIcon={<FilterList />}
            sx={{ color: theme.palette.highlight.icon }}
        >
            Filter
        </Button>
    )
}