import React, { createContext, useContext, useState } from 'react';
import dayjs from "dayjs";
import { getLeagueDefaults } from '../bond_report/exploreBonds/ExploreBonds.jsx'

const FilterContext = createContext();

export const LeagueFilterProvider = ({ children, limits }) => {
    const defaults = limits ? getLeagueDefaults(limits) : {}
    const [industry, setIndustry] = useState(defaults.industry);
    const [minAmountIssued, setMinAmountIssued] = useState(defaults.minAmountIssued);
    const [maxAmountIssued, setMaxAmountIssued] = useState(defaults.maxAmountIssued);
    const [minFirstAnnouncementDate, setMinFirstAnnouncementDate] = useState(defaults.minFirstAnnouncementDate);
    const [maxFirstAnnouncementDate, setMaxFirstAnnouncementDate] = useState(defaults.maxFirstAnnouncementDate);
    const [minLifeSpan, setMinLifeSpan] = useState(defaults.minLifeSpan);
    const [maxLifeSpan, setMaxLifeSpan] = useState(defaults.maxLifeSpan);
    const [minOriginalSpread, setMinOriginalSpread] = useState(defaults.minOriginalSpread);
    const [maxOriginalSpread, setMaxOriginalSpread] = useState(defaults.maxOriginalSpread);

    const value = {
        industry, setIndustry,
        minAmountIssued, setMinAmountIssued,
        maxAmountIssued, setMaxAmountIssued,
        minFirstAnnouncementDate, setMinFirstAnnouncementDate,
        maxFirstAnnouncementDate, setMaxFirstAnnouncementDate,
        minLifeSpan, setMinLifeSpan,
        maxLifeSpan, setMaxLifeSpan,
        minOriginalSpread, setMinOriginalSpread,
        maxOriginalSpread, setMaxOriginalSpread
    };

    return (
        <FilterContext.Provider value={value}>
            {children}
        </FilterContext.Provider>
    );
};

export const useLeagueFilter = () => {
    return useContext(FilterContext);
};