import Box from "@mui/material/Box";
import * as React from "react";
import { useState } from "react";
import LeagueTable from "./LeagueTable.jsx";
import { ParentSize } from "@visx/responsive";
import ScatterPlot from "../../components/plots/ScatterPlot.jsx";
import Switch from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import GraphBox from '../../components/GraphBox.jsx';
import BoxTitle from '../../components/headers/BoxTitle.jsx';
import HorizontalLine from '../../components/HorizontalLine.jsx';
import VolumeByQuarter from "../../components/plots/VolumeByQuarterHistogram.jsx";
import { useTheme } from '@mui/material/styles';



const Scatter = (props) => {
    const { points, xDomain, yDomain, title, onClick, height = '800px' } = props
    const [showRelative, setShowRelative] = useState(true)
    const handleChange = (event) => {
        setShowRelative(event.target.checked)
    }
    return (
        <>
            <GraphBox height={height}>
                <Box display="flex" justifyContent="space-between" paddingBottom="20px">
                    <BoxTitle title={showRelative ? `${title} (%)` : `${title} (m)`} />
                    <FormControlLabel
                        control={<Switch className="switch" checked={showRelative} onChange={handleChange} />}
                        label='Relative'
                    />
                </Box>
                <HorizontalLine />
                <div style={{ minHeight: 0, flex: '1' }}>
                    <ParentSize>
                        {({ width, height }) => <ScatterPlot
                            pointsRaw={points}
                            xDomain={xDomain}
                            yDomain={yDomain}
                            width={width}
                            showAxis={true}
                            showRelative={showRelative}
                            showVoronoi={false}
                            height={height}
                            onClick={onClick} />}
                    </ParentSize>
                </div>
            </GraphBox>
        </>
    )
}



const FinanceWall = (props) => {
    const { title, data,  width = 376, height = 330, flex } = props
    const theme = useTheme()
    const x = {
        values: data.map(x => x.quarter),
        label: 'Quarter'
    }
    const y = {
        values: data.map(x => (x.amountIssued / 1000).toFixed(1)),
        label: 'b'
    }
    const nBonds = data.map(x => x.nBonds)
    return (
        <GraphBox
            width={width}
            height={height}
            flex={flex}>
            <BoxTitle title={title} />
            <Box flex={1} paddingTop={'48px'} >
                <VolumeByQuarter 
                x={x} 
                y={y} 
                nBonds={nBonds} 
                color={theme.palette.primary[700]}
                />
            </Box>
        </GraphBox>
    )
}

export default function Bonds(props) {
    const { data, onRowClick } = props
    if (data) {
        return (
            <>
                {/*Scatter plot*/}
                <Box marginBottom={'24px'}>
                    <Scatter
                        points={data.leagueData}
                        xDomain={data.statistics.pricing.quantile_range_95}
                        yDomain={data.statistics.timing.quantile_range_95}
                        onClick={onRowClick}
                        title={'Pricing and Timing Distribution'} />
                </Box>

                {/* Historic and Refinance  Walls*/}
                <Box className='row-container' marginBottom={'24px'}>
                    <FinanceWall title={'Historic Issues'}
                        data={data.statistics.issueWall}
                        flex={' 1 1 0'}
                         />
                    <FinanceWall title={'Refinance Wall'}
                     data={data.statistics.refinanceWall}
                     flex={' 2 1 0'}
                    /> 
                </Box>

                {/* League Table*/}
                <Box marginBottom={'24px'}>
                    <LeagueTable
                        title={'League Table'}
                        data={data.leagueData}
                        onRowClick={onRowClick} />
                </Box>
            </>
        )
    }
}

