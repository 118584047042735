import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';



export const bondInfo = {
    spreadEarlySecondaryMarket:
        <Typography>
            Early secondary market development of the bond, the relevant average of comparable bonds (“Peers”)
            as well as the respective secondary market development of the mature secondary market (“Benchmark”).
            Spread = Government Spread.
        </Typography>,
    spreadToBenchmarkEarlySecondaryMarket:
        <Typography>
            Relative spread performance of the bond in relation to the relevant mature secondary market (Benchmark).
            <br />
            <br />
            For example: If the value of a bond is -10 bps on day 6, the Spread of the bond tightened 10 bps
            more than the respective secondary market benchmark.
            The value -10 bps can be seen as specific performance of the bond adjusted by overall market developments.
        </Typography>,
    spreadToPeersEarlySecondaryMarket:
        <Typography>
            Relative spread performance of the bond in relation to comparable peer bonds.
            Peer bonds are similar in terms of life span and creditworthiness.
            <br />
            <br />
            For example: If the value of the bond is -5 bps on day 6,
            the Spread of the bond tightened 5 bps more than the respective average of comparable peer bonds. The value -5 bps can be seen as specific performance of a newly issued bond in relation to the early secondary market performance of comparable bonds.
            Or in other words: new issue premium was 5 bps higher compared to peer bonds.
        </Typography>,
    debtRayPriceRankingSummary:
        <Typography>
            This spectrum shows the pricing-balance between issuer and investors.
            If the needle points to the dark red on the <b>left</b>-hand side, pricing was very much in favor of
            investors and therefore the issuer was <b>overpaying</b> in terms of pricing.
            If the needle points to the dark red on the <b>right</b>-hand side,
            pricing was too aggressive leaving investors <b>undercompensated</b> with risks taken in participating in new issuance.
            <br />
            <br />
            A value of e.g. -10% indicates a tightening of 10% of the original spread in the early secondary market,
            e.g. from 200 bps to 180 bps.
        </Typography>,
    alternativeInterestExpenses:
        <Typography>
            This graph shows the window of opportunity for a specific issuance.
            This window refers to 65 trading days (  ̴ 3 months), symmetrically around the day of issuance.
            Trading day # 0 indicates the actual day of issuance.
            <br />
            <br />
            A column in green indicates that this alternative trading day would have been a better day
            for this bond issuance and indicates the respective savings on this alternative trading day.
            <br />
            <br />
            A column in red indicates that this trading day would have been worse as an alternative day
            of issuance and indicates the respective additional interest expenses on this alternative trading day.
            <br />
            <br />
            The dotted line indicates the average of the alternative interest expenses in the window of
            opportunity under consideration.
            <br />
            <br />
            All values presented indicates the delta to realized total interest expenses.
        </Typography>,
    deltaSpread:
        <Typography>
            Delta Spread indicates the spread development during the early secondary market <b>adjusted</b> by the
            development of the relevant benchmark. Delta Spread indicates the pure,
            bond specific development independent from overall market developments.
            <br />
            <br />
            A negative value indicates an <b>adjusted</b> spread tightening during the early secondary market.
            An investor was better of in participating in this new issue compared to an investment
            in a comparable mature bond in the same period.
            <br />
            <br />
            A positive value indicates an <b>adjusted</b> spread widening during the early secondary market.
            An investor was worse of in participating in this new issue compared to an investment in a comparable mature bond in the same period.
        </Typography>,
    spreadDevelopment:
        <Typography>
            Delta Spread in relation to Original Spread in %.
        </Typography>,
    pricingLossForIssuer:
        <Typography>
            Pricing loss or Pricing gains indicates the total loss or gain of an issuer with regard to the volume issued
            and the life span of the bond. The calculation of the pricing loss/gain takes the average/appropriate new issue
            premium into account: if an issuer grants the optimal new issue premium the pricing loss/gain is 0 (pricing optimum).
        </Typography>
}

export const InfoDebtRayBondRanking = () => {
    const theme = useTheme()
    const squareLength = '55px'
    const ranks = [1, 2, 3, 4, 5]
    // {pricing: {timing: overall}}
    const assignment = {
        1: { 1: 1, 2: 1, 3: 2, 4: 3, 5: 3 },
        2: { 1: 1, 2: 1, 3: 2, 4: 3, 5: 4 },
        3: { 1: 2, 2: 2, 3: 3, 4: 3, 5: 4 },
        4: { 1: 3, 2: 3, 3: 3, 4: 4, 5: 5 },
        5: { 1: 3, 2: 4, 3: 4, 4: 5, 5: 5 },
    }

    const Square = ({ rank, fill = true }) => {
        const style = {
            backgroundColor: fill ? theme.palette.ranks[rank] : null,
            width: squareLength,
            height: squareLength,
            margin: '2px',
            borderColor: theme.palette.secondary['light'],
            borderWidth: '.5px',
            borderStyle: 'solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
        return (
            <div style={style}>
                <Typography>
                    {rank}
                </Typography>
            </div>)
    }

    const ColumnHeader = () => {
        return (
            <>
                <Typography>
                    TimingRank
                </Typography>
                <Box display={'flex'} marginBottom={'10px'}>
                    {ranks.map((timingRank, index) => (
                        <Square key={`matrix-col-index${index}`} rank={timingRank} fill={false} />
                    ))}
                </Box>
            </>
        )

    }
    const Matrix = () => {
        return (
            <>
                {ranks.map((pricingRank, rowIndex) => (
                    <Box display='flex' key={rowIndex}>
                        {ranks.map((timingRank, colIndex) => (
                            <Square key={`matrix-${rowIndex}-${colIndex}`} rank={assignment[pricingRank][timingRank]} />
                        ))}
                    </Box>
                ))}
            </>
        )
    }

    const RowHeader = () => {
        return (
            <Box marginRight={'-20px'}>
                <Typography style={{
                    transform: 'rotate(-90deg)',
                    whiteSpace: 'nowrap',
                    transformOrigin: 'left bottom',
                    position: 'relative',
                    right: '0px',
                    top: '95px'
                }}>
                    PricingRank
                </Typography>
                <Box display={'flex'} flexDirection={'column'}>
                    {ranks.map((pricingRank, index) => (
                        <Square key={`matrix-col-index${index}`} rank={pricingRank} fill={false} />
                    ))}
                </Box>
            </Box>
        )
    }

    const AssignmentMatrix = () => {
        return (
            <Box display={'flex'} alignItems={'end'} justifyContent={'center'}>
                <RowHeader />
                <Box>
                    <ColumnHeader />
                    <Matrix />
                </Box>
            </Box>
        )
    }

    const RankInterpretation = () => {
        return (
            <Box display={'flex'} gap={'20px'} justifyContent={'center'}>

                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Square rank={1} />
                    <Typography>Very Good</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Square rank={2} />
                    <Typography>Good</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Square rank={3} />
                    <Typography>Fair</Typography>
                </Box>

                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Square rank={4} />
                    <Typography>Poor</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Square rank={5} />
                    <Typography>Very Poor</Typography>
                </Box>

            </Box>
        )
    }

    return (
        <>
            <Typography paddingBottom={'20px'}>
                The ranking schemes are based on the following gradings:
            </Typography>
            <RankInterpretation />
            <Typography paddingBottom={'40px'} paddingTop={'40px'}>
                The BondRank is a composite of PricingRank  and TimingRank.
                <br></br>
                The associated assignment matrix is defined by:
            </Typography>
            <AssignmentMatrix />


        </>
    )
}