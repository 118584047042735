import * as React from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import RangeSlider from "../../components/RangeSlider";
import SelectDates from "../../components/SelectDates";
import Box from "@mui/material/Box";
import FilterLeagueButton from "../../components/FilterLeagueButton";
import BoxTitle from "../../components/headers/BoxTitle";
import { useTheme } from '@mui/material/styles';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getLeagueDefaults } from './ExploreBonds';


const MultiSelectButtons = (props) => {
    const { possibleValues, selectedValues, setSelectedValues } = props
    const isSelected = value => selectedValues === undefined ? false : selectedValues.includes(value)
    const handleButtonToggle = value => {
        if (selectedValues === undefined) {
            setSelectedValues([value])
        }
        else if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter(x => x !== value))
        }
        else {
            setSelectedValues([...selectedValues, value])
        }

    };

    return (
        <Box paddingLeft='80px' paddingRight='80px'>
            <ToggleButton
                sx={{ m: '8px' }}
                value={"Any"}
                key={"Any"}
                selected={(selectedValues === undefined) || (selectedValues.length === 0)}
                onChange={() => setSelectedValues(undefined)}
                aria-label={"Any"}
            >
                {"Any"}
            </ToggleButton>
            {possibleValues.map((value) => (
                <ToggleButton
                    sx={{ m: '8px' }}
                    value={value}
                    key={value}
                    selected={isSelected(value)}
                    onChange={() => handleButtonToggle(value)}
                    aria-label={value}
                >
                    {value}
                </ToggleButton>
            ))}

        </Box>
    );
};


function MyDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function FilterLeague({ selection, listeners, limits }) {

    const theme = useTheme()
    const defaults = getLeagueDefaults(limits)
    const [open, setOpen] = useState(false);
    // Use own states for dialog in order to allow request only upon "Show".
    // Also we can restore the previous requested state if a User cancels dialog. (se handleOpen method).
    const [industry, setIndustry] = useState(selection.industry)
    const [minFirstAnnouncementDate, setMinFirstAnnouncementDate] = useState(selection.minFirstAnnouncementDate)
    const [maxFirstAnnouncementDate, setMaxFirstAnnouncementDate] = useState(selection.maxFirstAnnouncementDate)
    const [minOriginalSpread, setMinOriginalSpread] = useState(selection.minOriginalSpread);
    const [maxOriginalSpread, setMaxOriginalSpread] = useState(selection.maxOriginalSpread);
    const [minLifeSpan, setMinLifeSpan] = useState(selection.minLifeSpan)
    const [maxLifeSpan, setMaxLifeSpan] = useState(selection.maxLifeSpan)
    const [minAmountIssued, setMinAmountIssued] = useState(selection.minAmountIssued)
    const [maxAmountIssued, setMaxAmountIssued] = useState(selection.maxAmountIssued)

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true)
    }


    const handleShow = () => {
        // Talk to api (via listeners) upon confirmation
        listeners.setIndustry(industry)
        listeners.setMinFirstAnnouncementDate(minFirstAnnouncementDate)
        listeners.setMaxFirstAnnouncementDate(maxFirstAnnouncementDate)
        listeners.setMinOriginalSpread(minOriginalSpread)
        listeners.setMaxOriginalSpread(maxOriginalSpread)
        listeners.setMinLifeSpan(minLifeSpan)
        listeners.setMaxLifeSpan(maxLifeSpan)
        listeners.setMinAmountIssued(minAmountIssued)
        listeners.setMaxAmountIssued(maxAmountIssued)
        handleClose()
    }

    const handleReset = () => {
        setIndustry(defaults.industry)
        setMinFirstAnnouncementDate(defaults.minFirstAnnouncementDate)
        setMaxFirstAnnouncementDate(defaults.maxFirstAnnouncementDate)
        setMinOriginalSpread(defaults.minOriginalSpread)
        setMaxOriginalSpread(defaults.maxOriginalSpread);
        setMinLifeSpan(defaults.minLifeSpan)
        setMaxLifeSpan(defaults.maxLifeSpan)
        setMinAmountIssued(defaults.minAmountIssued)
        setMaxAmountIssued(defaults.maxAmountIssued)

    }

    return (
        <>
            {/*Click on Filter Icon*/}
            <FilterLeagueButton onClick={handleOpen} />

            <Dialog open={open}
                onClose={handleClose}
                className="custom-dialog"
                scroll='paper'
                fullWidth={true}
                maxWidth='md'>
                <MyDialogTitle onClose={handleClose}>
                    Filter
                </MyDialogTitle>
                <DialogContent dividers>

                    <Box mb={7} mt={4}>
                        <BoxTitle title={'First Announcement Date'} paddingBottom="16px"/>
                        <SelectDates
                            minDate={minFirstAnnouncementDate}
                            maxDate={maxFirstAnnouncementDate}
                            setMinDate={setMinFirstAnnouncementDate}
                            setMaxDate={setMaxFirstAnnouncementDate}
                            minLimit={limits.minFirstAnnouncementDate}
                            maxLimit={limits.maxFirstAnnouncementDate}
                        />
                    </Box>

                    <Box mb={7}>
                        <BoxTitle title={'Original Spread'} />
                        <RangeSlider
                            minValue={minOriginalSpread}
                            maxValue={maxOriginalSpread}
                            setMinValue={setMinOriginalSpread}
                            setMaxValue={setMaxOriginalSpread}
                            minLimit={limits.minOriginalSpread}
                            maxLimit={limits.maxOriginalSpread}
                            unit={'Bps'}
                        />
                    </Box>

                    <Box mb={7}>
                        <BoxTitle title={'Amount Issued'} />
                        <RangeSlider
                            minValue={minAmountIssued}
                            maxValue={maxAmountIssued}
                            setMinValue={setMinAmountIssued}
                            setMaxValue={setMaxAmountIssued}
                            minLimit={limits.minAmountIssued}
                            maxLimit={limits.maxAmountIssued}
                            unit={'M'}
                        />
                    </Box>

                    <Box mb={7}>
                        <BoxTitle title={'Life Span'} />
                        <RangeSlider
                            minValue={minLifeSpan}
                            maxValue={maxLifeSpan}
                            setMinValue={setMinLifeSpan}
                            setMaxValue={setMaxLifeSpan}
                            minLimit={limits.minLifeSpan}
                            maxLimit={limits.maxLifeSpan}
                            unit={'Years'}
                        />
                    </Box>


                    <Box mb={7} display="flex" flexDirection="column">
                        <BoxTitle title={'Industry'} paddingBottom="16px"/>
                        <MultiSelectButtons
                            possibleValues={limits.industry}
                            selectedValues={industry}
                            setSelectedValues={setIndustry}
                        />
                    </Box>

                </DialogContent>
                    <Box padding='16px' display="flex" justifyContent="space-between">
                        <Box>
                            <Button
                                autoFocus
                                onClick={handleReset}
                                sx={{ color: theme.palette.highlight.icon }}
                                startIcon={<RestartAltIcon />}
                            >
                                Reset
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                autoFocus
                                onClick={handleShow}
                                sx={{ color: theme.palette.highlight.icon }}
                            >
                                Show
                            </Button>
                        </Box>
                    </Box>
            </Dialog>
        </>
    )
}