import { Bar } from 'react-chartjs-2';
import { defaults } from 'chart.js';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useTheme } from '@mui/material/styles';


Chart.register(
    annotationPlugin,
    ArcElement,
    BarElement,
    CategoryScale,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
)
export default function HistogramPlot(props) {
    const theme = useTheme()
    const { x, y,  color = theme.palette.primary[600]} = props
    defaults.font.size = 12
    defaults.font.family = theme.typography.fontFamily
    
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                display: true,
                title: {
                    display: false,
                    text: x.label,
                    color: theme.palette.secondary.main,
                    padding: {top: 33},

                },
                border: { display: false },
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                    // round ticks to integer
                    callback: function(val){return this.getLabelForValue(val).toFixed(0)},
                    color: theme.palette.secondary.main,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: y.label,
                    color: theme.palette.secondary.main,
                    padding: {bottom: 33} // padding w.r.t text (rotatet in this case)
                },
                
                border: { display: false },
                grid: {
                    display: true,
                    color: theme.palette.secondary.veryLight,
                },
                ticks: {
                    color: theme.palette.secondary.main,
                },

            },
        },
        plugins: {
            tooltip: {
                backgroundColor: '#FFFFFF',
                displayColors: false,
                titleColor: theme.palette.secondary.dark,
                bodyColor: theme.palette.secondary.light,
                borderWidth: 2,
                borderColor: theme.palette.secondary.veryLight,
                caretSize: 0, // no tooltip arrow
                caretPadding: 10

            },
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };


    const chart_data = {
        labels: x.values,
        datasets: [
            {
                label: y.label,
                data: y.values,
                borderRadius: 2,
                backgroundColor: color,
                hoverBackgroundColor: theme.palette.highlight.main
            },
        ],
    };
    return (<Bar options={options} data={chart_data} />)
}

