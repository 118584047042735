import { ListItemText } from "@mui/material";
import Search from '../../components/Search';


export default function SearchCompany({ candidates, onSearchResultClick, label,  width }) {
    // clearAfterClick + width only for SignUp
    const ShowResultItem = ({ result }) => { return <ListItemText primary={result.parentCompany} /> }
    return (
        <Search
            candidates={candidates}
            onSearchResultClick={onSearchResultClick}
            searchFields={['parentCompany']}
            id={'parentCompany'}
            ShowResultItem={ShowResultItem}
            label={label}
            width={width}
        />
    )

}