import { Bar } from 'react-chartjs-2';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useTheme } from '@mui/material/styles';
import { defaults } from 'chart.js';



Chart.register(
    annotationPlugin,
    ArcElement,
    BarElement,
    CategoryScale,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
)
const TimeSeriesBarPlot = (props) => {

    const { x, y, yMean, xUnit, yUnit, precision = 0, colorize=false } = props
    const theme = useTheme()
    defaults.font.size = 12
    defaults.font.family = theme.typography.fontFamily
    const backgroundColor = y.values.map((y) => y > 0 ? theme.palette.ranks[4] : theme.palette.ranks[2])
    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: x.label,
                    color: theme.palette.secondary.main,
                    padding: { top: 33 },
                },
                border: { display: false },
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                    color: theme.palette.secondary.main,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                title: {
                    display: true,
                    text: yUnit,
                    color: theme.palette.secondary.main,
                    padding: { bottom: 33 }
                },
                border: { display: false },
                grid: {
                    display: true,
                    color: theme.palette.secondary.veryLight,
                },
                ticks: {
                    color: theme.palette.secondary.main,
                },

            },
        },
        responsive: true,
        plugins: {
            tooltip: {
                backgroundColor: '#FFFFFF',
                displayColors: false,
                titleColor: theme.palette.secondary.dark,
                bodyColor: theme.palette.secondary.light,
                borderWidth: 2,
                borderColor: theme.palette.secondary.veryLight,
                caretSize: 0, // no tooltip arrow
                caretPadding: 10,
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.parsed.y.toFixed(precision)} ${yUnit}`;
                    },
                    title: function (tooltipItems) {
                        return `Delta Day: ${tooltipItems[0].label}`;
                    },
                },

            },
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
                position: 'top'
            },
            title: {
                display: false,
                text: '',
            },

            annotation: {
                annotations: [
                    {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: x.values.indexOf(0),
                        borderColor: theme.palette.highlight.main,
                        borderWidth: 2,
                        // borderDash: [5, 5],
                    },
                    {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y',
                        value: yMean,
                        borderColor: theme.palette.highlight.main,
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                ],
            },
        },
    };


    const chart_data = {
        labels: x.values,
        datasets: [
            {
                label: y.label,
                data: y.values,
                borderRadius: 3,
                backgroundColor: colorize ? backgroundColor : theme.palette.primary[600],
                hoverBackgroundColor: theme.palette.highlight.main
            },
        ],
    };
    return (<Bar options={options} data={chart_data} />)
}

export default TimeSeriesBarPlot