
export default function LabelCircle({ color }) {
    const style = {
        width: '10px',
        height: '10px',
        backgroundColor: color,
        border: '1px solid #667085',
        borderRadius: '50%',
        marginRight: '8px',
    }
    return (<div style={style}></div>) 
}