import { redirect, useNavigate } from "react-router-dom";
import InfoPage from '../components/InfoPage';



const Logout = () => {
    const navigate = useNavigate();

    const logout = async () => {
        try {
            await fetch('/api/auth/logout');
        }
        catch (error) {
            console.log("Error logging out", error);
        }
        finally {
            // TODO - /login does NOT redirect if user is already logged in,
            //  hence there is no indication to user, whether logOUT was successful at all.
            // navigate('/login');
        }
    };

    logout(); // Perform logout when the component mounts
    return <InfoPage title={"You have been sucessfully logged out!"} redirectToLogin = {true} />; 
};

export default Logout;
