import React from 'react';
import { Bar, BarGroup } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend'
import { GridRows, GridColumns } from '@visx/grid';
import { useTheme } from '@mui/material/styles';




const margin = { left: 30, right: 10, top: 30, bottom: 30 }
const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;



const x0 = (d) => d.amountIssuedBin;
const keys = ['pricing', 'timing', 'overall'];


export default function AverageRankByVolume(props) {
    const { data, width, height } = props
    const theme = useTheme()
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const x0Scale = scaleBand({
        domain: data.map(x0),
        round: true,
        range: [0, xMax],
        padding: 0.3
    });

    const x1Scale = scaleBand({
        domain: keys,
        round: true,
        range: [0, x0Scale.bandwidth()],
        padding: 0.3
    });

    const yScale = scaleLinear({
        domain: [5, 1],
        range: [yMax, 0]
    });

    const color = scaleOrdinal({
        //domain: keys.map((x) => capitalize(x)),
        domain: keys,
        range: [theme.palette.primary[300], theme.palette.primary[500], theme.palette.primary[700]]
    });

    return width < 10 ? null : (
        <div style={{ position: 'absolute' }}>
            <svg width={width} height={height} fontFamily={theme.typography.fontFamily}>
                <Group top={margin.top} left={margin.left}>
                    <GridRows scale={yScale}
                        width={xMax}
                        height={yMax}
                        tickValues={[1, 2, 3, 4, 5]}
                    />
                    <BarGroup
                        data={data}
                        keys={keys}
                        height={yMax}
                        x0={x0}
                        x0Scale={x0Scale}
                        x1Scale={x1Scale}
                        yScale={yScale}
                        color={color}>
                        {(barGroups) =>
                            barGroups.map((barGroup) => {
                                return (
                                    <Group
                                        key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                                        left={barGroup.x0}
                                    >
                                        {barGroup.bars.map((bar) =>
                                        // need to got to d3.js level in order to get half rounded bars
                                        // stack two bars: one with rounded corners ('upper') and overlay with non rounded courners ('lower')
                                        (
                                            <Group key={`${barGroup.index}-${bar.index}-${bar.key}`}>
                                                <rect
                                                    key={`${barGroup.index}-${bar.index}-${bar.key}-upper`}
                                                    x={bar.x}
                                                    y={bar.y}
                                                    width={bar.width}
                                                    height={bar.height}
                                                    fill={bar.color}
                                                    rx={6}
                                                />
                                                <rect
                                                    key={`${barGroup.index}-${bar.index}-${bar.key}-lower`}
                                                    x={bar.x}
                                                    y={bar.y + bar.height / 2}
                                                    width={bar.width}
                                                    height={bar.height / 2}
                                                    fill={bar.color}
                                                    rx={0}
                                                />
                                            </Group>
                                        ))}
                                    </Group>
                                )
                            })
                        }
                    </BarGroup>

                </Group>
                <AxisBottom
                    top={yMax + margin.top}
                    left={margin.left}
                    scale={x0Scale}
                    hideAxisLine
                    hideTicks
                    stroke={theme.palette.secondary.main}
                    tickStroke={theme.palette.secondary.main}
                    tickLabelProps={{
                        fontSize: 12,
                        fontFamily: theme.typography.fontFamily,
                        fill: theme.palette.secondary.main,
                        dy: '10px',
                        textAnchor: 'middle',
                    }} />
                <AxisLeft
                    left={margin.left}
                    top={margin.top}
                    scale={yScale}
                    hideAxisLine
                    hideTicks
                    stroke={theme.palette.secondary.main}
                    tickValues={[1, 2, 3, 4, 5]}
                    tickStroke={theme.palette.secondary.main}
                    tickFormat={(x) => x.toString()}
                    tickLabelProps={{
                        fontSize: 12,
                        fontFamily: theme.typography.fontFamily,
                        //dx: '-.7em',
                        fill: theme.palette.secondary.main,
                        textAnchor: 'middle',
                    }} />

            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                    fontSize: '14px',
                    fontFamily: theme.typography.fontFamily
                }}>
                <LegendOrdinal
                    scale={color}
                    direction="row"
                    shape='circle'
                    labelMargin="0 15px 0 0"
                    labelFormat={(label) => capitalize(label)}
                    />
            </div>
        </div>
    )

}

