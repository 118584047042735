export const getIndices = (array, values) => array.reduce((acc, value, i) => {
    values.includes(value) && acc.push(i);
    return acc
}, [])

export const slice = (array, indices) =>  indices.map((i) => array[i])

export const sum = (array) => array.reduce((acc, curr) => acc + curr, 0)

export const sortByField = (data, field) => data.sort((a, b) => a[field] - b[field]);

export const filterNotNull = (data, field) => data.filter(item => (item[field] !== undefined) && (item[field] !== null) && (!isNaN(item[field])));

export const assignIndex = (data) => data.map((data, i) => ({ ...data, id: i }))

export const normalizeArray = (xs) => {return xs.map(x => x - xs[0])}

export const flattenDict = (data) => {
    // Transform dict of lists to list of dicts
    // assumes that there is a field 'x' which is an array
    // {a: 1, b: [1,2,3]} -> [ {a:1, b:1}, {a:1, b:2}, {a:1, b:3}]
    const keys = Object.keys(data)
    const indices = data.x.map((x, i) => i)
    return indices.map(index => {
        return keys.reduce((acc, key) => {
            const value = data[key]
            if (!Array.isArray(value)) {
                acc[key] = value
            } else {
                acc[key] = value[index]
            }
            return acc
        }, {})
    }
    )
}


const flattenArrays = (...arrays) => {
    return arrays.reduce((flattenedArray, currentArray) => {
        return flattenedArray.concat(currentArray);
    }, []);
}

const filterNan = (xs) => {
    return xs.filter(item => (item !== undefined) && (item !== null) && (!isNaN(item)));
}

export const mutualMin = (...arrays) => {
    return Math.min(...filterNan(flattenArrays(...arrays)))
}

export const mutualMax = (...arrays) => {
    return Math.max(...filterNan(flattenArrays(...arrays)))
}