import React, { useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar, AreaClosed, LinePath, Line } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';
import { TooltipWithBounds,  defaultStyles, withTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material";
import { extent, bisector } from '@visx/vendor/d3-array';
import { flattenDict, filterNotNull } from '../../helpers/arrayHelpers';
import { ClipPath } from '@visx/clip-path';
import { LinearGradient } from '@visx/gradient';


const getX = (d) => d.x
const getY = (d) => d.y
const getErrorLow = (d) => d.yErrorLow
const getErrorHigh = (d) => d.yErrorHigh

const margin = { top: 20, bottom: 70, left: 70, right: 20 };

const tooltipStyles = {
    ...defaultStyles,
    borderRadius: 8,
    padding: 12
};

const transform = (x, ys) => {
    const result = ys.map(y => flattenDict({ ...x, ...y }))
    return [].concat(...result)
}

export default withTooltip(({ width,
    height,
    x,
    ys,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft = 0 }) => {
    if (width < 10) { return null }

    const theme = useTheme()
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const ysToShow = ys.filter(d => d.show === true)
    if (ysToShow.length === 0) { return null }

    let data = transform(x, ysToShow)
    const charts = ysToShow.map(y => y.yLabel)
    const chartProps = ysToShow.reduce((acc, y) => {
        acc[y.yLabel] = {
            color: y.color,
            strokeWidth: y.strokeWidth ?? 3,
            showError: 'yErrorLow' in y && 'yErrorHigh' in y
        };
        return acc
    }, {})
    const labelColor = 'white'


    const xScale = useMemo(
        () =>
            scaleLinear({
                range: [margin.left, width - margin.right],
                domain: extent(data, getX),
                nice: true
            }),
        [data, width],
    );

    const yScale = useMemo(
        () => scaleLinear({
            range: [height - margin.bottom, margin.top],
            domain: extent(data, getY),
            nice: true,
        }),
        [data, height],
    );


    const handleMouseOver = useCallback(
        (event) => {
            const bisectDay = bisector(getX).left
            const { x } = localPoint(event) || { x: 0 };
            const x0 = xScale.invert(x);
            const index = bisectDay(data, x0, 1)
            const d0 = data[index - 1];
            const d1 = data[index];
            let d = d0;
            if (d1 && getX(d1)) {
                d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
            }
            const tooltipData = data.filter(t => getX(t) === getX(d)) // several entries for one day
            showTooltip({
                tooltipData: tooltipData,
                tooltipLeft: xScale(getX(d)),
            });
        },
        [showTooltip, xScale, data]
    );


    return (
        <div style={{ position: 'relative' }}>
            <svg width={width} height={height}>

                
                {/* Clip path if error boundaries are present as they could be ouside the domain */}
                <ClipPath id="clip">
                    <rect x={margin.left} y={margin.top} width={innerWidth} height={innerHeight} />
                </ClipPath>

                {/* Background */}
                <LinearGradient
                    id="background-gradient"
                    vertical={false}
                    from={theme.palette.primary[700]}
                    to={theme.palette.primary[300]} />
                <rect
                    width={width}
                    height={height}
                    rx={5}
                    fill={"url(#background-gradient)"}/>

                <Group>
                    {/* Draw zero line for guid of eyes */}

                    {charts.map((chart, i) => {
                        const chartData = data.filter(d => d.yLabel === chart)
                        return (
                            <Group key={`chart-${i}`}>
                                <LinePath
                                    key={`line-chart-${i}`}
                                    data={filterNotNull(chartData, 'y')}
                                    x={(d) => xScale(getX(d))}
                                    y={(d) => yScale(getY(d))}
                                    stroke={chartProps[chart].color}
                                    strokeWidth={chartProps[chart].strokeWidth}
                                    curve={curveMonotoneX} />

                                 {chartProps[chart].showError &&
                                <g clipPath="url(#clip)">
                                    <AreaClosed
                                        key={`error-area-chart-${i}`}
                                        data={chartData}
                                        x={(d) => xScale(getX(d))}
                                        y0={(d) => yScale(getErrorLow(d))}
                                        y1={(d) => yScale(getErrorHigh(d))}
                                        yScale={yScale}
                                        stroke='transparent'
                                        fill={chartProps[chart].color}
                                        fillOpacity={.2}
                                        curve={curveMonotoneX}
                                    />
                                </g>
                                } 
                            </Group>
                        )
                    })}

                    <AxisLeft
                        scale={yScale}
                        left={margin.left}
                        numTicks={5}
                        /* tickValues={yScale.domain()} */
                        stroke={labelColor}
                        tickStroke={labelColor}
                        label="bps"
                        labelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: labelColor,
                            //dx: '.5em'
                        }}
                        tickLabelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: labelColor,
                            dx: '-.5em',
                        }} />


                    <AxisBottom
                        scale={xScale}
                        top={height - margin.bottom}
                        stroke={labelColor}
                        tickStroke={labelColor}
                        numTicks={10}
                        label="Trading Days since Issuance"
                        labelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: labelColor,
                            dy: '1em'
                        }}
                        tickLabelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: labelColor,
                            dy: '.5em'
                        }} />

                    <Bar
                        x={margin.left}
                        y={margin.top}
                        width={innerWidth}
                        height={innerHeight}
                        fill="transparent"
                        onTouchStart={handleMouseOver}
                        onTouchMove={handleMouseOver}
                        onMouseMove={handleMouseOver}
                        onMouseLeave={() => hideTooltip()} />

                    {tooltipData && (
                        <g>
                            {/* Vertical hover line */}
                            <Line
                                from={{ x: tooltipLeft, y: margin.top }}
                                to={{ x: tooltipLeft, y: height - margin.bottom }}
                                stroke={labelColor}
                                strokeWidth={1}
                                strokeOpacity={1}
                                pointerEvents="none"
                                strokeDasharray="5,2" />
                            {charts.map((chart, i) => {
                                const chartData = tooltipData.filter(d => d.yLabel === chart)[0]
                                if ((isNaN(chartData.y)) || (chartData.y === null)) {return null} 
                                return <circle
                                    key={`tooltip-circle-${i}`}
                                    cx={tooltipLeft}
                                    cy={yScale(chartData.y)}
                                    r={chartProps[chart].strokeWidth + 2}
                                    stroke={"white"}
                                    strokeWidth={1}
                                    fill={chartProps[chart].color}
                                    fillOpacity={1}
                                    pointerEvents="none" />
                            })}
                        </g>
                    )}
                </Group>
            </svg>

            {tooltipData && (
                <TooltipWithBounds
                    key={Math.random()}
                    top={margin.top - 50}
                    left={tooltipLeft}
                    style={tooltipStyles}>
                    <>
                        <Typography
                            color={theme.palette.secondary.main}
                            fontSize={12}
                            fontWeight={600}
                            marginBottom={'8px'}>
                            {`Day ${tooltipData[0].x.toFixed(0)}`}
                        </Typography>
                        {charts.map((chart, i) => {
                            const chartData = tooltipData.filter(d => d.yLabel === chart)[0]
                            return <Typography
                                key={`tooltip-chart-${i}`}
                                color={theme.palette.secondary.light}
                                fontSize={12}
                                fontWeight={500}>
                                {`${chart}: ${Math.round(chartData.y)} ${chartData.yUnit} `}
                            </Typography>
                        })}
                    </>
                </TooltipWithBounds>
            )}


        </div>
    )


}

)