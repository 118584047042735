import { useState } from "react";
import React from 'react';
import { ListItemButton, ListItemText, TextField, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import { FixedSizeList } from 'react-window';


export default function Search(props) {
    const { candidates,
        onSearchResultClick,
        searchFields,
        id,
        ShowResultItem,
        minHeight = 50,
        label = "Search",
        width = '320px' } = props
    const [searchText, setSearchText] = useState('')

    let handleSearchTextChange = (e) => {
        setSearchText(e.target.value.toLowerCase())
    }

    let handleSearchResultClick = (e) => {
        onSearchResultClick(e)
        setSearchText('')

    }
    return (
        <div style={{position: 'relative'}}>
            <SearchBar
                value={searchText}
                onChange={handleSearchTextChange}
                label={label}
                width={width} />
            <ShowSearchResults
                candidates={candidates}
                searchText={searchText}
                searchFields={searchFields}
                id={id}
                onClick={handleSearchResultClick}
                ShowResultItem={ShowResultItem}
                minHeight={minHeight} />
        </div>
    )
}


function SearchBar({ value, onChange, label, width }) {
    return (
        <TextField
            id="bondReportSearchBar"
            label={label}
            type="search"
            fullWidth
            variant="outlined"
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <img src="/public/icons/search-lg.svg" alt="Search" />
                    </InputAdornment>
                ),
                sx: { borderRadius: '8px', width: width }
            }}
        />

    )
}



function Screen(candidates, searchText, searchFields) {
    const searchResult = []

    candidates.forEach((candidate) => {
        if (searchText === '') {
            return;
        }
        if (searchFields
            .map(field => candidate[field].toLowerCase().indexOf(searchText) === -1)
            .every(value => value)
        ) {
            return;
        }
        searchResult.push(candidate)
    }
    )
    return searchResult
}




const ShowSearchResults = ({ candidates, searchText, searchFields, id, onClick, ShowResultItem, minHeight }) => {
    // Show search results virtualized by using react.window (Fixed Size list)
    const searchResult = Screen(candidates, searchText, searchFields)
    const totalHeight = Math.min(500, searchResult.length * minHeight)
    if (searchResult.length === 0) { return null }

    const renderRow = ({ index, style }) => {
        const result = searchResult[index]
        return (
            <ListItemButton
                sx={{ ...style, borderRadius: '8px' }}
                onClick={() => onClick(result[id])}>
                {<ShowResultItem result={result} />}
            </ListItemButton>
        )
    }
    return (
        <Box
            position='absolute'
            right='0px' 
            marginTop='8px'
            width='400px'
            backgroundColor='white'
            border="1px solid #EAECF0"
            borderRadius={'8px'}
            padding={'8px'}
            sx={{ maxHeight: '500px', zIndex: 1000 }}>
            <FixedSizeList
                width={400}
                height={totalHeight}
                itemCount={searchResult.length}
                itemSize={minHeight}
            >
                {renderRow}
            </FixedSizeList>
        </Box>
    )
}
