import React, { createContext, useContext, useState } from 'react';

const SharedIsinContext = createContext();

export const useSharedIsin = () => {
  return useContext(SharedIsinContext);
};

export const SharedIsinProvider = ({ children }) => {
  const [isin, setIsin] = useState(undefined);

  return (
    <SharedIsinContext.Provider value={{ isin, setIsin }}>
      {children}
    </SharedIsinContext.Provider>
  );
};