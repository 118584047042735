import React, { useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar, AreaClosed, LinePath, Line } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';
import { TooltipWithBounds, defaultStyles, withTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { assignIndex, sortByField, filterNotNull } from '../../helpers/arrayHelpers.js';
import { LinearGradient } from '@visx/gradient';
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material"
import { bisector } from '@visx/vendor/d3-array';


const margin = { top: 20, bottom: 20, left: 60, right: 30 };
const primaryColor = "#FFF"

const tooltipStyles = {
    ...defaultStyles,
    borderRadius: 8,
    padding: 12
};

const assignValue = (data, field, clamp) => data.map((data) => (
    {
        ...data,
        y: clamp ? Math.min(Math.max(data[field], -40), 40) : data[field]
    })
)


function prepareData(data, field, clamp) {
    const result = assignIndex(sortByField(filterNotNull(data, field), field))
    return assignValue(result, field, clamp)
}

export default withTooltip(({ width,
    height,
    raw_data,
    field,
    onClick,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0 }) => {
    const theme = useTheme()
    // need to copy read only array via spread operator
    const data = prepareData([...raw_data], field, true)

    // don't render too small graphs or no prepared data
    if (data.length === 0) { return (<div>No data available</div>) }

    const minLoss = data[0].y
    const maxLoss = data[data.length - 1].y

    const getX = (d) => d.id
    const getY = (d) => d.y
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;



    const xScale = useMemo(
        () =>
            scaleLinear({
                range: [margin.left, width - margin.right],
                domain: [data[0].id, data[data.length - 1].id + 1],
            }),
        [data, width],
    );

    const yScale = useMemo(
        () =>
            scaleLinear({
                range: [height - margin.bottom, margin.top],
                nice: true,
                //domain: [minLoss, maxLoss],
                domain: [-40, 40]
            }),
        //[minLoss, maxLoss, height],
        [height]
    );


    const handleMouseOver = useCallback(
        (event) => {
            const bisectIndex = bisector(getX).left
            const { x } = localPoint(event) || { x: 0 };
            const x0 = xScale.invert(x);
            const index = bisectIndex(data, x0, 1)
            const d0 = data[index - 1];
            const d1 = data[index];
            let d = d0;
            if (d1 && getX(d1)) {
                d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
            }
            showTooltip({
                tooltipData: d,
                tooltipLeft: xScale(d.id) || xScale.range()[0], // make it snap
                tooltipTop: yScale(d.y),
            });
        },
        [showTooltip, xScale, yScale, data],
    );

    const handleMouseClick = useCallback(
        (event) => {
            const bisectIndex = bisector(getX).left
            const { x } = localPoint(event) || { x: 0 };
            const x0 = xScale.invert(x);
            const index = bisectIndex(data, x0, 1)
            const d0 = data[index - 1];
            const d1 = data[index];
            let d = d0;
            if (d1 && getX(d1)) {
                d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
            }
            onClick(d.isin)
        },
        [xScale, data, onClick],
    );

    if (width < 10) { return null }

    return (
        <div style={{ position: 'relative', cursor: 'pointer' }}>
            <svg width={width} height={height}>

                <LinearGradient
                    id="line-gradient"
                    vertical={false}
                    from='#FFF'
                    to='#FFF'
                    fromOpacity={.8}
                    toOpacity={0.3}
                />

                <LinearGradient
                    id="background-gradient"
                    vertical={false}
                    from={theme.palette.primary[700]}
                    to={theme.palette.primary[300]} />

                <rect
                    width={width}
                    height={height}
                    rx={5}
                    fill={"url(#background-gradient)"}
                />

                <Group>

                    <AreaClosed
                        data={data}
                        x={(d) => xScale(getX(d))}
                        y0={(d) => yScale(getY(d))}
                        y1={(d) => yScale(0)}
                        yScale={yScale}
                        stroke='transparent'
                        fill={"url(#line-gradient)"}
                        curve={curveMonotoneX}
                    />

                    <LinePath
                        data={data}
                        x={(d) => xScale(getX(d))}
                        y={(d) => yScale(getY(d))}
                        stroke={"url(#line-gradient)"}
                        strokeWidth={2}
                        curve={curveMonotoneX}

                    />

                    <AxisLeft
                        scale={yScale}
                        left={margin.left}
                        numTicks={10}
                        stroke={primaryColor}
                        tickStroke={primaryColor}
                        tickLabelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: primaryColor,
                            dx: '-1em',
                        }}

                    />
                    <AxisBottom
                        scale={xScale}
                        top={yScale(0)}
                        stroke={primaryColor}
                        numTicks={5}
                        label=""
                        hideTicks
                        tickLabelProps={() => ({ display: 'none' })}

                    />
                    <Bar
                        x={margin.left}
                        y={margin.top}
                        width={innerWidth}
                        height={innerHeight}
                        fill="transparent"
                        onTouchStart={handleMouseOver}
                        onTouchMove={handleMouseOver}
                        onMouseMove={handleMouseOver}
                        onMouseLeave={() => hideTooltip()}
                        onClick={handleMouseClick}

                    />
                    {tooltipData && (
                        <g>
                            {/* Vertical hover line */}
                            <Line
                                from={{ x: tooltipLeft, y: margin.top }}
                                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                                stroke="white"
                                strokeWidth={1}
                                strokeOpacity={1}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                            {/* Horizontal hover line */}
                            <Line
                                from={{ x: margin.left, y: tooltipTop }}
                                to={{ x: margin.left + innerWidth, y: tooltipTop }}
                                stroke="white"
                                strokeWidth={1}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop}
                                r={6}
                                fill={theme.palette.highlight.main}
                                stroke='white'
                                strokeWidth={2}
                                fillOpacity={1}
                                pointerEvents="none"
                            />
                        </g>
                    )}
                </Group>
            </svg>

            {tooltipData && (
                <>
                    <TooltipWithBounds
                        key={Math.random()}
                        top={tooltipTop - 12}
                        left={tooltipLeft}
                        style={tooltipStyles}>
                        <>
                            <Typography
                                color={theme.palette.secondary.main}
                                fontSize={12}
                                fontWeight={600}
                                marginBottom={'8px'}>
                                {tooltipData.issuer}
                            </Typography>
                            <Typography
                                color={theme.palette.secondary.light}
                                fontSize={12}
                                fontWeight={500}>
                                {`${tooltipData[field].toFixed(1)} m Euro`}
                            </Typography>
                        </>

                    </TooltipWithBounds>
                </>
            )}
        </div>
    )
}

)