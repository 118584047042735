import React, { useCallback, useMemo, useRef } from "react"
import { Group } from "@visx/group"
import { Circle } from "@visx/shape"
import { scaleLinear } from "@visx/scale"
import { withTooltip, defaultStyles, TooltipWithBounds } from "@visx/tooltip"
import { Line } from '@visx/shape';
import { voronoi, VoronoiPolygon } from "@visx/voronoi"
import { localPoint } from "@visx/event"
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Text } from '@visx/text';
import { LinearGradient } from '@visx/gradient';
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material"
import { computeQuantiles } from "../../helpers/pricingInterpolation"


// filter data: 
const filterNotNull = data => data.filter(item => item.pricingLossForIssuer !== null && item.timingAverageGain !== null)
const transform = (data) => {
    return data.map(item => {
        return {
            ...item,
            pricingLoss: item.pricingLossForIssuer,
            timingGain: item.timingAverageGain,
            relativePricingLoss: item.pricingLossForIssuer / item.amountIssued * 100,
            relativeTimingGain: item.timingAverageGain / item.amountIssued * 100
        }
    })
}

const preProcess = (data) => transform(filterNotNull(data))
const getQuantiles = (data, field) => {
    const xs = data.map(item => item[field])
    return computeQuantiles(xs, [0.025, 0.975], 'linear')
}

// layout
const margin = { top: 30, bottom: 60, left: 70, right: 30 }

const tooltipStyles = {
    ...defaultStyles,
    borderRadius: 8,
    padding: 12
};

let tooltipTimeout

export default withTooltip(
    ({
        pointsRaw,
        showAxis,
        showRelative,
        showVoronoi,
        onClick,
        width,
        height,
        hideTooltip,
        showTooltip,
        tooltipOpen,
        tooltipData,
        tooltipLeft,
        tooltipTop
    }) => {
        if (width < 10) return null
        const svgRef = useRef(null)
        const theme = useTheme()


        const points = useMemo(
            () => preProcess(pointsRaw),
            [pointsRaw]
        )

        const x = useCallback((d) => {
            return showRelative ? d.relativePricingLoss : d.pricingLoss
        }, [showRelative])

        const y = useCallback((d) => {
            return showRelative ? d.relativeTimingGain : d.timingGain
        },
            [showRelative])

        const xScale = useMemo(
            () =>
                scaleLinear({
                    domain: getQuantiles(points, showRelative ? "relativePricingLoss" : "pricingLoss"),
                    range: [margin.left, width - margin.right],
                    clamp: true
                }),
            [width, points, showRelative]
        )

        const yScale = useMemo(
            () =>
                scaleLinear({
                    domain: getQuantiles(points, showRelative ? "relativeTimingGain" : "timingGain"),
                    range: [height - margin.bottom, margin.top],
                    clamp: true
                }),
            [height, points, showRelative]
        )

        const voronoiLayout = useMemo(
            () =>
                voronoi({
                    x: d => xScale(x(d)),
                    y: d => yScale(y(d)),
                    width: width,
                    height: height,

                })(points),
            [width, height, xScale, yScale, points, x, y]
        )

        // event handlers
        const handleMouseMove = useCallback(
            event => {
                if (tooltipTimeout) clearTimeout(tooltipTimeout)
                if (!svgRef.current) return
                // find the nearest polygon to the current mouse position
                const point = localPoint(svgRef.current, event)
                if (!point) return
                const neighborRadius = 100
                const closest = voronoiLayout.find(point.x, point.y, neighborRadius)
                if (closest) {
                    showTooltip({
                        tooltipLeft: xScale(x(closest.data)),
                        tooltipTop: yScale(y(closest.data)),
                        tooltipData: closest.data
                    })
                }
            },
            [xScale, yScale, showTooltip, voronoiLayout, x, y]
        )

        const handleMouseClick = useCallback(
            event => {
                if (!svgRef.current) return
                // find the nearest polygon to the current mouse position
                const point = localPoint(svgRef.current, event)
                if (!point) return
                const neighborRadius = 100
                const closest = voronoiLayout.find(point.x, point.y, neighborRadius)
                if (closest) {
                    onClick(closest.data.isin)
                }
            },
            [voronoiLayout, onClick]
        )

        const handleMouseLeave = useCallback(() => {
            tooltipTimeout = window.setTimeout(() => {
                hideTooltip()
            }, 10)
        }, [hideTooltip]
        )

        return (
            <div style={{ cursor: 'pointer' }}>
                <svg width={width} height={height} ref={svgRef}>
                    {/* Background Gradient */}
                    {/* <LinearGradient id="customGradient" from={'#FCFCFD'} to={theme.palette.secondary['light']} /> */}
                    <LinearGradient id="customGradient" from={theme.palette.secondary[300]} to={theme.palette.secondary[600]} />

                    {/** capture all mouse events with a rect */}
                    <rect
                        width={width}
                        height={height}
                        rx={0}
                        fill="url(#customGradient)"
                        //fill="white"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                        onTouchMove={handleMouseMove}
                        onTouchEnd={handleMouseLeave}
                        onClick={handleMouseClick}
                    />

                    {/** Bounding box */}
                    <rect
                        x={margin.left}
                        y={margin.top}
                        width={width - margin.left - margin.right}
                        height={height - margin.top - margin.bottom}
                        stroke={'white'}
                        fill="none"
                    />

                    {/** Guide of eye: horizontal line at y=0 */}
                    <Line
                        from={{ x: margin.left, y: yScale(0) }}
                        to={{ x: width - margin.right, y: yScale(0) }}
                        stroke="white"
                    />

                    {/** Guide of eye: vertical line at y=0 */}
                    <Line
                        from={{ x: xScale(0), y: margin.top }}
                        to={{ x: xScale(0), y: height - margin.bottom }}
                        stroke="white"
                    />


                    {showAxis &&
                        <>
                            <AxisBottom
                                top={height - margin.bottom}
                                left={0}
                                //hideZero
                                scale={xScale}
                                stroke="white"
                                tickStroke="white"
                                numTicks={7}
                                tickLabelProps={{
                                    fontSize: 14,
                                    fill: 'white',
                                    fontFamily: theme.typography.fontFamily,

                                }} />
                            <AxisLeft
                                left={margin.left}
                                top={0}
                                //hideZero
                                verticalAnchor='end'
                                scale={yScale}
                                stroke="white"
                                tickStroke="white"
                                numTicks={5}
                                tickLabelProps={{
                                    fontSize: 14,
                                    fontFamily: theme.typography.fontFamily,
                                    fill: 'white',
                                }}
                            />
                            <Text
                                x={margin.left}
                                y={height - margin.bottom + 40}
                                fontFamily={theme.typography.fontFamily}
                                fill={'white'}
                                verticalAnchor='middle'
                                textAnchor='start'
                                fontWeight={700}
                                fontSize={14}
                            >
                                Pricing Loss
                            </Text>
                            <Text
                                x={width - margin.right}
                                y={height - margin.bottom + 40}
                                fontFamily={theme.typography.fontFamily}
                                fill={'white'}
                                verticalAnchor='middle'
                                textAnchor='end'
                                fontWeight={700}
                                fontSize={14}
                            >
                                Pricing Gain
                            </Text>

                            <Text
                                x={margin.left - 50}
                                y={margin.top}
                                verticalAnchor="middle"
                                textAnchor='end'
                                fontFamily={theme.typography.fontFamily}
                                fill={'white'}
                                fontWeight={700}
                                fontSize={14}
                                angle={-90}

                            >
                                Timing Gain
                            </Text>

                            <Text
                                x={margin.left - 50}
                                y={height - margin.bottom}
                                verticalAnchor="middle"
                                textAnchor='start'
                                fontFamily={theme.typography.fontFamily}
                                fill={'white'}
                                fontWeight={700}
                                fontSize={14}
                                angle={-90}
                            >
                                Timing Loss
                            </Text>
                        </>
                    }
                    <Group pointerEvents="none">
                        {points.map((point, i) => (
                            <Circle
                                key={`point-${point[0]}-${i}`}
                                className="dot"
                                cx={xScale(x(point))}
                                cy={yScale(y(point))}
                                r={tooltipData === point ? 12 : 5.5}
                                fill={point.overallRank !== null ? theme.palette.ranks[point.overallRank] : 'transparent'}
                                stroke={"white"}
                                strokeWidth={tooltipData === point ? 3 : 1}
                                opacity={1}
                            />
                        ))}
                        {showVoronoi &&
                            voronoiLayout
                                .polygons()
                                .map((polygon, i) => (
                                    <VoronoiPolygon
                                        key={`polygon-${i}`}
                                        polygon={polygon}
                                        fill="white"
                                        stroke="white"
                                        strokeWidth={1}
                                        strokeOpacity={0.2}
                                        fillOpacity={tooltipData === polygon.data ? 0.5 : 0}
                                    />
                                ))}
                    </Group>


                </svg>
                {tooltipOpen &&
                    tooltipData &&
                    tooltipLeft != null &&
                    tooltipTop != null && (
                        <TooltipWithBounds left={tooltipLeft}
                            top={tooltipTop}
                            style={tooltipStyles}
                        >
                            <>
                                <Typography
                                    color={theme.palette.secondary.main}
                                    fontSize={12}
                                    fontWeight={600}
                                    marginBottom={'8px'}>
                                    {tooltipData.issuer}
                                </Typography>
                                <Typography
                                    color={theme.palette.secondary.light}
                                    fontSize={12}
                                    fontWeight={500}>
                                    BondRank: {tooltipData.overallRank !== null ? tooltipData.overallRank : "Not available yet"} <br />
                                    Volume: {`${tooltipData.amountIssued} m`} <br />
                                    {tooltipData.pricingLoss <= 0 ?
                                        `Pricing loss: ${tooltipData.pricingLoss.toFixed(1)} m (${tooltipData.relativePricingLoss.toFixed(2)} %)` :
                                        `Pricing gain: ${tooltipData.pricingLoss.toFixed(1)} m (${tooltipData.relativePricingLoss.toFixed(2)} %)`} <br />
                                    PricingRank: {tooltipData.pricingRank} <br />
                                    {/* Delta spread: {tooltipData.deltaSpread.toFixed(1)} Bps. <br /> */}
                                    {tooltipData.timingGain <= 0 ?
                                        `${tooltipData.timingRank === null ? "Estimated" : ""} Timing loss: ${tooltipData.timingGain.toFixed(1)} m (${tooltipData.relativeTimingGain.toFixed(2)} %)` :
                                        `${tooltipData.timingRank === null ? "Estimated" : ""} Timing gain: ${tooltipData.timingGain.toFixed(1)} m (${tooltipData.relativeTimingGain.toFixed(2)} %)`} <br />
                                    {/* Timing gain: {y(tooltipData).toFixed(1)} m <br /> */}
                                    TimingRank: {tooltipData.timingRank !== null ? tooltipData.timingRank : "Not available yet"} <br />
                                </Typography>
                            </>
                        </TooltipWithBounds>
                    )}
            </div>
        )
    }
)
