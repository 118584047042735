import {Slider, styled, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";


const DebtRaySlider = styled(Slider)(({theme}) => ({
    color: theme.palette.highlight.main,
    height: 3,
    padding: '32px 0',
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .debtray-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color:  '#d8d8d8',
        opacity: 1,
        height: 3,
    },
}));


export default function RangeSlider(props) {
    const {minValue, maxValue, setMinValue, setMaxValue, minLimit, maxLimit, unit} = props

    const handleSliderChange = (event, newValue) => {
        setMinValue(newValue[0]);
        setMaxValue(newValue[1]);
    };

    const handleMinInputChange = (event) => {
        const newValue = Number(event.target.value);
        setMinValue(newValue);
    };

    const handleMaxInputChange = (event) => {
        const newValue = Number(event.target.value);
        setMaxValue(newValue);
    };

    return (
        <Box paddingLeft='80px' paddingRight='80px'>
            <DebtRaySlider
                value={[minValue ?? minLimit, maxValue ?? maxLimit]}
                onChange={handleSliderChange}
                valueLabelDisplay="off"
                disableSwap
                min={minLimit}
                max={maxLimit}
                step={1}
            />
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <TextField
                    label="Min"
                    value={minValue ?? minLimit}
                    onChange={handleMinInputChange}
                    sx={{width: '40%'}}
                    InputProps={{
                        startAdornment: <Typography>{unit}&nbsp;</Typography>
                    }}
                />
                <div className="range-bar"></div>
                <TextField
                    label="Max"
                    value={maxValue ?? maxLimit}
                    onChange={handleMaxInputChange}
                    sx={{width: '40%'}}
                    InputProps={{
                        startAdornment: <Typography>{unit}&nbsp;</Typography>
                    }}
                />
            </Box>
        </Box>)
}