import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import Slide from '@mui/material/Slide';

export const Snack = ({ open, onClose, severity, title, message, direction="left", actionComponent }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={10000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={(props) => <Slide {...props} direction={direction}/>}
        >
            <Alert
                onClose={onClose}
                severity={severity}
                elevation={6}
                style={{ backgroundColor: 'white' }}>
                <Typography
                    /* color={theme.palette[severity].main}  */
                    fontWeight={600}
                    fontSize={'15px'}>
                    {title}
                </Typography>
                <p>{message}</p>
                {actionComponent && actionComponent}
            </Alert>

        </Snackbar>
    )
}