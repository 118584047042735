import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { handleNull } from '../helpers/handleNull';

const Circle = ({ children, diameter, color }) => {
    const circleStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: diameter,
        height: diameter,
        borderRadius: '50%',
        border: 'none',
        backgroundColor: color
    };

    return (
        <div style={circleStyle}>
            {children}
        </div>
    );

}

export default function CircleNumber({ rank, label, diameter }) {
    const innerDiameter = diameter - 24
    const theme = useTheme()
    const style = {
        marginTop: 'auto', // makes circles aligned to baseline 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px',
        flex: '1 0 0',
    };

    return (
        <div style={style}>
            <Circle diameter={diameter.toString() + 'px'} color={theme.palette.ranks[rank]}>
                <Circle diameter={innerDiameter.toString() + 'px'} color={theme.palette.primary[700]}>
                    <Typography
                        fontSize={rank === null ? '18px' : '48px'}
                        color='#FFF'>
                        {handleNull(rank)}
                    </Typography>
                </Circle>

            </Circle>
            <div >
                <Typography fontSize='16px'>
                    {label}
                </Typography>
            </div>
        </div>
    )


};