import React from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Arc, Circle } from '@visx/shape';
import { GridRadial } from '@visx/grid';
import { Text } from '@visx/text';
import { useTheme } from '@mui/material/styles';
import { quantile } from 'simple-statistics'
import { computeQuantiles, linearInterpolation, percentileThresholds, rankPercentileThreshold } from '../../helpers/pricingInterpolation';


const relativeSpreadToOriginalSpread = (targetRelativeSpreads, realtiveSpread, originalSpread) => {
    return targetRelativeSpreads.map((x) => (realtiveSpread + 1) / (x + 1) * originalSpread)
}

const originalSpreadToRelativeSpread = (targetOriginalSpread, realtiveSpread, originalSpread) => {
    return (realtiveSpread + 1) * originalSpread / targetOriginalSpread - 1
}

// layout 
const padding = 50

const radToDegree = (radians) => { return (radians * 180) / Math.PI; }
function polarToCartesian(alpha, r) {
    // Need to subtract Pi/2 because the angles are taken from the vertical axis in svg
    return {
        x: r * Math.cos(alpha - Math.PI / 2),
        y: r * Math.sin(alpha - Math.PI / 2)
    }
}

export default function PricingGaugePlotSimulator(props) {
    const { width, height, data, targetOriginalSpread } = props
    const theme = useTheme()
    const centerY = height / 2
    const centerX = width / 2

    // Thresholds on original spread scale
    //const relativeSpreadThresholds = data.data.pricingRankThresholds // compute them from scratch
    const relativeSpreadThresholds = computeQuantiles(data.data.relativeDeltaSpreadPeer, percentileThresholds)
    const originalSpreadThresholds = relativeSpreadToOriginalSpread(relativeSpreadThresholds, data.data.relativeDeltaSpread, data.data.originalSpread)


    // Simulation 
    // 1. Compute the new hypothetial relative spread
    const simulatedRelativeDeltaSpread = originalSpreadToRelativeSpread(targetOriginalSpread, data.data.relativeDeltaSpread, data.data.originalSpread)
    // 2. Compute the rank of this new realative spread w.r.t to the Peer realtive spread
    const simulatedPricingPercentile = linearInterpolation(relativeSpreadThresholds, percentileThresholds, simulatedRelativeDeltaSpread)

    // Also for original spread
    const originalPricingPercentile = linearInterpolation(relativeSpreadThresholds, percentileThresholds, data.data.relativeDeltaSpread)
    // The pyton result would make a slight difference - which is consistent with the left plot
    // const originalPricingPercentile =data.data.pricingPercentile
    if ((width < 10) || (relativeSpreadThresholds === null) || (simulatedPricingPercentile === null)) return null;


    // percentile range in fraction - NOT percentage
    const xScale = scaleLinear({
        domain: [0, 1],
        range: [-Math.PI / 4 * 3, Math.PI / 4 * 3],
    })

    // radius scale could correspond to issuer loss 
    const yScale = scaleLinear({
        domain: [0, 1],
        range: [0, centerY - padding]
    });

    const angle = (x) => xScale(x)
    const radius = (y) => yScale(y)

    const Arrow = ({ value, fill }) => {
        const rotationAngle = radToDegree(angle(value))
        const points = [
            { x: -radius(.05), y: radius(0) },
            { x: radius(.05), y: radius(0) },
            { x: radius(.015), y: - radius(.8) },
            { x: -radius(.015), y: - radius(.8) },
        ];
        const cornerRadius = 2.5
        const controlPoint = cornerRadius * Math.tan((Math.PI / 180) * 45); // 45 degrees
        const pathString = `
        M${points[0].x},${points[0].y} 
        L${points[1].x},${points[1].y} 
        L${points[2].x},${points[2].y} 
        Q${points[2].x - controlPoint} ${points[2].y - cornerRadius}
         ${points[3].x},${points[3].y} 
        Z`;
        return (
            <Group transform={`rotate(${rotationAngle} 0, 0)`}>
                <path d={pathString} fill={fill} />
            </Group>
        );
    };

    const AnnotateLimits = () => {
        const radius = Math.max(...yScale.range())
        const alpha_min = Math.min(...xScale.range())
        const alpha_max = Math.max(...xScale.range())
        const cart_min = polarToCartesian(alpha_min, radius);
        const cart_max = polarToCartesian(alpha_max, radius);
        return (
            <>
                <Text
                    key={"text-overpaying"}
                    x={cart_min.x}
                    y={cart_min.y}
                    dx={0}
                    dy={0}
                    angle={radToDegree(alpha_min) + 90}
                    fontSize='15'
                    textAnchor='start'
                    verticalAnchor='start'
                    scaleToFit>
                    Overpaying
                </Text>
                <Text
                    key={"text-undercompensated"}
                    x={cart_max.x}
                    y={cart_max.y}
                    dx={0}
                    dy={0}
                    angle={radToDegree(alpha_max) - 90}
                    fontSize='15'
                    textAnchor='end'
                    verticalAnchor='start'
                    scaleToFit>
                    Undercomp.
                </Text>
                <Text
                    key={"text-unit"}
                    x={0}
                    y={yScale(1)}
                    dy={10}
                    textAnchor='middle'
                    verticalAnchor='start'
                    fill='black'
                    fillOpacity={1}
                    scaleToFit>
                    Original Spread (bps)
                </Text>
            </>
        )
    }

    return (
        <div>
            <svg width={width} height={height}>
                <Group left={centerX} top={centerY} >

                    {/* Pies for the ranks */}
                    {rankPercentileThreshold.map((rank, i) => {
                        const outerRadius = radius(Math.max(...yScale.domain()))
                        const innerRadius = radius(Math.min(...yScale.domain())) + outerRadius / 2
                        return (
                            <Arc
                                key={`arc-${i}`}
                                startAngle={angle(rank.low)}
                                endAngle={angle(rank.high)}
                                outerRadius={outerRadius}
                                innerRadius={innerRadius}
                                padAngle={0.02}
                                cornerRadius={3}
                                /* fill={theme.palette.ranks[overrideRank(rank, simulatedPricingPercentile, deltaSpread)]} */
                                fill={theme.palette.ranks[rank.rank]}
                            />)
                    })
                    }

                    {/* Angular grid line labels */}
                    {percentileThresholds.map((value, i) => {
                        const alpha = angle(value)
                        const cart = polarToCartesian(angle(value), 1.07 * yScale.range()[1])
                        return (
                            <Text
                                x={cart.x}
                                y={cart.y}
                                key={`angular-label-${i}`}
                                angle={radToDegree(alpha)}
                                textAnchor={'middle'}
                                verticalAnchor='middle'
                                fill='black'
                                fontFamily={theme.typography.fontFamily}
                                fillOpacity={1}
                            >
                                {`${originalSpreadThresholds[i].toFixed(0)}`}
                            </Text>
                        )
                    }
                    )}

                    {/* Radial grid lines */}
                    <GridRadial
                        scale={yScale}
                        numTicks={5}
                        stroke='black'
                        strokeWidth={1}
                        fill={'transparent'}
                        strokeOpacity={0.1}
                    /* startAngle={angle(0)}
                    endAngle={angle(1)} */
                    />

                    {/* Change of original spread */}
                    <Arc
                        key={'arc-change'}
                        startAngle={angle(originalPricingPercentile)}
                        endAngle={angle(simulatedPricingPercentile)}
                        outerRadius={radius(0)}
                        innerRadius={radius(.8)}
                        padAngle={0.0}
                        cornerRadius={3}
                        fill={'#667085'}
                        opacity={.5}
                    />

                    {/* Arrow */}
                    <Circle cx={0} cy={0} r={radius(.1)} fill='#344054' />
                    <Arrow value={simulatedPricingPercentile} fill={'#344054'} />

                    {/* Annotation */}
                    <AnnotateLimits />


                </Group>
            </svg>
        </div>
    )

}