
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import Info from '../../components/Info.jsx';



export const InfoTable = ({ rows }) => {

    const WithInfo  = (title,  info) => {
        return (
            <Box display="flex" alignItems="flex-start">
                {title}
                {<Info title={title} info={info} fontSize={'medium'} />}
            </Box>
        )
    }
    const tableStyle = {
        size: "small",
        table: { borderCollapse: 'collapse' }, // Remove outer borders
        cell: { borderBottom: 'none' }, // Remove bottom borders for cells
    };
    return (
        <Box marginLeft='-10px'>
            <Table size={tableStyle.size}>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.key}>
                            <TableCell style={tableStyle.cell}>
                                {row.info ? WithInfo(row.key, row.info) : row.key}
                            </TableCell>
                            <TableCell style={tableStyle.cell}>{row.value}</TableCell>
                        </TableRow>)
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}