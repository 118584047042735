import { useEffect, useState } from "react"
import { InfoTable } from '../components/tables/InfoTable'
import GraphBox from '../components/GraphBox'
import BoxTitle from '../components/headers/BoxTitle';
import { useTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";
import CachedIcon from '@mui/icons-material/Cached';
import HorizontalLine from '../components/HorizontalLine.jsx';
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip';
import { Snack } from '../components/Snack'
import fetchWithRefresh from "../helpers/api.js";


// Todo: Fetching meta data not really necessary as we have meta already at top level App(). 
// So it would be enough to propagate the meta data.
const fetchMeta = async (setData) => {
    try {
        const response = await fetchWithRefresh('/api/bond_ranking/meta');
        const result = await response.json();
        setData(result);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const triggerReload = async (setOpenSnack, setSnackMessage) => {
    try {
        const response_bonds = await fetchWithRefresh('/api/bond_ranking/update');
        const response_syndicate = await fetchWithRefresh('/api/bond_ranking/syndicate/update');
        //const result_bonds = await response_bonds.json();
        //const result_syndicate = await response_syndicate.json();
        if (response_bonds.ok && response_syndicate.ok) {
            setSnackMessage({
                title: "Data update for dashboard triggered...",
                message: 'Please reload page after a few seconds to see changes.',
                severity: 'success'
            })
        } else {
            setSnackMessage({
                title: "Could not update dasboard data",
                severity: 'error'
            })
        }
    } catch (error) {
        setSnackMessage({
            title: "Could not update dasboard data",
            severity: 'error'
        })
    }
    setOpenSnack(true)

}



const ReloadData = () => {
    const theme = useTheme()
    const [openSnack, setOpenSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState({ title: undefined, message: undefined, severity: undefined })
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    return (
        <>
            <Tooltip title="Reloads Dashboard components - Handle with care!">
                <Button
                    onClick={() => triggerReload(setOpenSnack, setSnackMessage)}
                    startIcon={<CachedIcon />}
                    sx={{ color: theme.palette.highlight.icon }}
                >
                    Reload Dashboard Cache
                </Button>
            </Tooltip>
            <Snack
                open={openSnack}
                onClose={handleSnackClose}
                severity={snackMessage.severity}
                title={snackMessage.title}
                message={snackMessage.message}
            />
        </>
    )
}

const DataStatusTable = ({ data }) => {
    const rows = [
        { key: 'Last dashboard update', value: data.importDate },
        { key: 'Min date', value: data.availableLeagueLimits.minFirstAnnouncementDate },
        { key: 'Max date', value: data.availableLeagueLimits.maxFirstAnnouncementDate },
        { key: 'Number of bonds', value: data.nBonds },
        { key: 'Number of fully ranked bonds', value: data.nRankedBonds },
        { key: 'Number of companies', value: data.nCompanies },
    ];
    return <InfoTable rows={rows} />
}

export const DataStatus = ({ flex }) => {
    // Todo: also add Syndicate, which is a view on its own
    const [data, setData] = useState(undefined)
    useEffect(() => {
        fetchMeta(setData)
    }, [])

    // This if statement should go away
    return (
        <GraphBox paddingBottom='16px' flex={flex}>
            <Box display="flex" justifyContent="space-between" paddingBottom="20px">
                <BoxTitle title={"Data Summary"} />
                <ReloadData />
            </Box>
            <HorizontalLine />
            {data && <DataStatusTable data={data} />}
        </GraphBox>
    )
}