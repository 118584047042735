import { useTheme } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import { defaults } from 'chart.js';

Chart.register(
    annotationPlugin,
    ArcElement,
    BarElement,
    CategoryScale,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
    ChartDataLabels,
)

const parseQuarter = (quarter) => {return quarter.slice(-2) + '/' + quarter.slice(0,4) }
const getYear = (quarter) => {return quarter.slice(0,4) }
const getQuarter = (quarter) => {return quarter.slice(-2) }

const VolumeByQuarter = (props) => {
    const { x, y, nBonds, color } = props
    const theme = useTheme()
    defaults.font.size = 12
    defaults.font.family = theme.typography.fontFamily
    const options = {
        indexAxis: 'x', // flip bars
        maintainAspectRatio: false,
        animation: true,
        scales: {
            x: {
                display: true,
                title: {
                    display: false,
                    text: x.label,
                    color: color,
                    font: {
                        size: 12,
                    },
                },
                grid: {
                    display: false,
                },
                ticks: {
                    color: color,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function(value, index, ticks) {
                        // Format the tick label using the parseQuarter function
                        const label = this.getLabelForValue(value);
                        // Display tick labels as years at the beginning of the year 
                        // Uncomment this part if also the first year, not starting with Q1 should be shown
                        //if (index === 0) {
                        //    return [getQuarter(label), getYear(label)]
                        //} else 
                        if (label.includes('Q1')) {
                            return [getQuarter(label), getYear(label)]
                        } else {
                            return null
                        }
                    }
                },
            },
            y: {
                display: true,
                border: { display: true },
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                    color: theme.palette.secondary.main,
                    font: {
                        size: 12,
                        weight: 'medium',
                    },
                },
                title: {
                    display: true,
                    text: `Volume (${y.label})`,
                    color: color,
                    font: {
                        size: 12,
                    },
                },
            },
        },
        responsive: true,
        plugins: {
            tooltip: {
                backgroundColor: '#FFFFFF',
                displayColors: false,
                titleColor: theme.palette.secondary.dark,
                bodyColor: theme.palette.secondary.light,
                borderWidth: 2,
                borderColor: theme.palette.secondary.veryLight,
                caretSize: 0, // no tooltip arrow
                caretPadding: 10,
                callbacks: {
                    label: function (context) {
                        // Line Break by using array of strings 
                        return [`Volume: ${context.parsed.y} b`, `${nBonds[context.dataIndex]} Bonds`];
                    },
                    title: function (tooltipItems) {
                        return `Quarter: ${parseQuarter(tooltipItems[0].label)}`;
                    },
                },

            },
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };


    const chart_data = {
        labels: x.values,
        datasets: [
            {
                label: y.label,
                data: y.values,
                //barThickness: 10,
                backgroundColor: color,
                hoverBackgroundColor: theme.palette.highlight.main,
                borderRadius: 3,
            },
        ],
    };
    return (<Bar options={options} data={chart_data} />)
}

export default VolumeByQuarter