import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from "@mui/material/Box";
import { InputAdornment, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import InfoPage from '../components/InfoPage';


const inputProps = { sx: { borderRadius: '8px' } }

const sx = {
    mb: '30px',
    '&.Mui-focused': {
        color: '#1A3C7F',
        transform: 'none',
        transformOrigin: 'none',
    },
}


const RegistrationForm = ({ email, one_time_password }) => {
    const [password, setPassword] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [errorOccurred, setErrorOccured] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrorOccured(false)
    };

    const handlePasswordAgainChange = (event) => {
        setPasswordAgain(event.target.value);
        setErrorOccured(false)
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const checkPassword = () => {
        if (password !== passwordAgain) {
            throw new Error("Passwords don't agree!")
        }
        if (password === '') {
            throw new Error("Password is empty!")
        }
    }

    const registerBackend = async () => {
        const response = await fetch('/api/auth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                one_time_password: one_time_password,
                password: password
            })
        });
        if (!response.ok) {
            const errorResponse = await response.json()     
            throw new Error(errorResponse.error)
        }
    };

    const login = async () => {
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
        if (!response.ok) {
            throw new Error(`Could not log in ${email}`);
        }

        // TODO - as opposed to Login.jsx, the following is missing:
        //             setSettings(getSettingsFromCookie())
        //             setSnackbarMessage('Login successful');
        //             setSnackbarOpen(true);
        //  why?
        //  consider reusing instead of duplicating code

        navigate('/');

    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            checkPassword()
            await registerBackend()
            await login()
        }
        catch (error) {
            // todo handle error 
            setErrorOccured(true)
            setErrorMessage(error.message)
        }
        finally {
            setLoading(false)
        }
    }


    return (
        <>
            <TextField id="register-password"
                sx={sx}
                label="Set password"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    ...inputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField id="register-password-again"
                sx={sx}
                label="Repeat Password"
                value={passwordAgain}
                onChange={handlePasswordAgainChange}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    ...inputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {errorOccurred &&
                <Typography fontSize='14px' color='#F04438' marginBottom='15px'>
                    {errorMessage}
                </Typography>
            }

            <Button
                className="button"
                variant='contained'
                fullWidth
                type="submit"
                onClick={handleSubmit}
                sx={{ mb: '30px' }}
                disabled={loading}
            >
                Register
            </Button>
        </>
    )

}


const TermsOfUse = () => {
    const theme = useTheme()
    return (
        <Box display='flex' justifyContent='left' mb='56px'>
            <Typography fontWeight={500} color={theme.palette.secondary.light}>
                Proceeding with registration means that you agree with our &nbsp;
                <a href='https://debtray.com/terms' rel="noopener noreferrer">
                    Terms of use.
                </a>
            </Typography>
        </Box>

    )
}

export default function Register() {
    // todo use rather base64 encoded stuff
    // maybe a registration token is better to discuss with markus?


    // todo: if registered sucessfully log user in and redirect to home
    const [searchParams] = useSearchParams();
    const one_time_password = searchParams.get('otp') || '';
    const email = searchParams.get('email') || '';
    const expiry = searchParams.get('expiry') || ''

    const expiryTime = new Date(expiry)
    const currentTime = new Date()
    const hasExpired = expiryTime < currentTime

    if ((email === '' || one_time_password === '')) {
        return <InfoPage title={"Page not found!"} />
    }

    if (hasExpired) {
        return <InfoPage title={"This registration page has expired!"} />
    }

    return (
        <>
            <div className='login-page'>
                <Box padding='60px' alignSelf='start'>
                    <img src="/public/logos/logo_dark_mode.svg" alt="DebtRay" className="logo" />
                </Box>
                <Box className='login-container'>
                    <Typography fontSize='36px' fontWeight='600' paddingBottom='32px'>Register</Typography>
                    <TermsOfUse />
                    <RegistrationForm email={email} one_time_password={one_time_password} />
                </Box>
            </div>
        </>
    );
}
