import * as React from 'react';
import { useEffect, useState, useCallback } from "react";
import BasicOverview from "./BasicKpis";
import TimingOverview from "./TimingAnalysis";
import PricingOverview from "./PricingAnalysis"
import { Box } from '@mui/material';
import { useSharedIsin } from '../../contexts/SharedIsin';
import SearchBond from './SearchBond';
import PageTitle from '../../components/headers/PageTitle';
import { IdTitle } from '../../components/headers/IdTitle';
import fetchWithRefresh from '../../helpers/api';
import useHandleError from '../../helpers/handleError';


export function Bond() {
    const { isin, setIsin } = useSharedIsin()
    const [data, setData] = useState(undefined)
    const [searchCandidates, setSearchCandidates] = useState([])
    const [error, setError] = useState(null)
    const handleError = useHandleError() 
  
    

    useEffect(() => {
        fetchWithRefresh('/api/bond_ranking/search/bond')
            .then((resp) => resp.json())
            .then(setSearchCandidates)
            .catch((error) => {
                console.log("Fetch error bond search", error)
                setError(error)

            })
    }, [])


    useEffect(() => {
        if (typeof isin !== 'undefined') {
            fetchWithRefresh(`/api/bond_ranking/?isin=${isin}`)
                .then((r) => r.json())
                .then(setData)
                .catch((error) => {
                    console.log("Fetch error bond ranking", error)
                    setError(error)
                })
        }
    }, [isin])
 
    handleError(error)

    return (
        <div className='page-container'>
            <Box display={'flex'} justifyContent={'space-between'}>
                <PageTitle
                    title={'BondRanking'}
                    icon={<img src="/public/icons/bar-line-chart.svg" alt="Bond Analysis" />} />
                <SearchBond candidates={searchCandidates} onSearchResultClick={setIsin} />
            </Box>
             {data &&
                <>
                    <IdTitle id={data.data.issuer} />
                    <BasicOverview data={data} />
                    <PricingOverview data={data} />
                    <TimingOverview data={data} />
                </>} 
        </div>
    )
}

export default Bond