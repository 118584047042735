import React, { useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar, AreaClosed, LinePath, Line } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';
import { TooltipWithBounds, Tooltip, defaultStyles, withTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material";
import { extent, bisector } from '@visx/vendor/d3-array';
import { flattenDict, filterNotNull } from '../../helpers/arrayHelpers';


const getX = (d) => d.x
const getY = (d) => d.y
const margin = { top: 20, bottom: 70, left: 70, right: 20 };
const primaryColor = "#FFF"

const tooltipStyles = {
    ...defaultStyles,
    borderRadius: 8,
    padding: 12
};


export default withTooltip(({ width,
    height,
    x,
    y,
    yRange,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0 }) => {
    const theme = useTheme()
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const data =  useMemo(() => (flattenDict({x: x, y: y})), [x,y]);

    const xScale = useMemo(
        () =>
            scaleLinear({
                range: [margin.left, width - margin.right],
                domain: extent(data, getX),
            }),
        [data, width],
    );

    const yScale = useMemo(
        () => {
            let domain = yRange
            // if no range specified take it from data
            if (yRange === undefined) {domain = extent(data, getY)}
            // Make sure 0-line included
            if (domain[1] < 0) { domain[1] = 0 }
            if (domain[0] > 0) { domain[0] = 0 }

            return scaleLinear({
                range: [height - margin.bottom, margin.top],
                domain: domain,
                nice: true,
            })
        },
        [data, height, yRange],
    );


    const handleMouseOver = useCallback(
        (event) => {
            const bisectDay = bisector(getX).left
            const { x } = localPoint(event) || { x: 0 };
            const x0 = xScale.invert(x);
            const index = bisectDay(data, x0, 1)
            const d0 = data[index - 1];
            const d1 = data[index];
            let d = d0;

            if (d1 && getX(d1)) {
                d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
            }
            showTooltip({
                tooltipData: d,
                tooltipLeft: xScale(getX(d)),
                tooltipTop: yScale(getY(d)),
            });
        },
        [showTooltip, xScale, yScale, data]
    );

    if (width < 10) { return null }

    return (
        <div style={{ position: 'relative' }}>
            <svg width={width} height={height}>
                <LinearGradient
                    id="background-gradient"
                    vertical={false}
                    from={theme.palette.primary[700]}
                    to={theme.palette.primary[300]} />
                <rect
                    width={width}
                    height={height}
                    rx={5}
                    fill={"url(#background-gradient)"}/>
                <Group>
                    <AreaClosed
                        data={filterNotNull(data, 'y')}
                        x={(d) => xScale(getX(d))}
                        y1={(d) => yScale(getY(d))}
                        y0={(d) => yScale(0)}
                        yScale={yScale}
                        stroke='transparent'
                        fill={"#FFF"}
                        fillOpacity={.2}
                        curve={curveMonotoneX}/>
                    <LinePath
                        data={filterNotNull(data, 'y')}
                        x={(d) => xScale(getX(d))}
                        y={(d) => yScale(getY(d))}
                        stroke={"#FFF"}
                        strokeWidth={3}
                        curve={curveMonotoneX}/>
                    <AxisLeft
                        scale={yScale}
                        left={margin.left}
                        numTicks={5}
                        /* tickValues={yScale.domain()} */
                        stroke={primaryColor}
                        tickStroke={primaryColor}
                        label="bps"
                        labelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: primaryColor,
                            //dy: '1em'
                        }}
                        tickLabelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: primaryColor,
                            dx: '-.5em',
                        }} />
                    <AxisBottom
                        scale={xScale}
                        top={height - margin.bottom}
                        stroke={primaryColor}
                        tickStroke={primaryColor}
                        numTicks={10}
                        label="Trading Days since Issuance"
                        labelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: primaryColor,
                            dy: '1em',
                            textAnchor: 'middle'
                        }}
                        tickLabelProps={{
                            fontSize: 12,
                            fontFamily: theme.typography.fontFamily,
                            fill: primaryColor,
                            dy: '.5em'
                        }} />

                    <Bar
                        x={margin.left}
                        y={margin.top}
                        width={innerWidth}
                        height={innerHeight}
                        fill="transparent"
                        onTouchStart={handleMouseOver}
                        onTouchMove={handleMouseOver}
                        onMouseMove={handleMouseOver}
                        onMouseLeave={() => hideTooltip()}/>

                    {tooltipData && tooltipTop && (
                        <g>
                            {/* Vertical hover line */}
                            <Line
                                from={{ x: tooltipLeft, y: tooltipTop }}
                                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                                stroke="white"
                                strokeWidth={1}
                                strokeOpacity={1}
                                pointerEvents="none"
                                strokeDasharray="5,2"/>
                            
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop}
                                r={6}
                                fill={theme.palette.highlight.main}
                                stroke='white'
                                strokeWidth={3}
                                fillOpacity={1}
                                pointerEvents="none"/>
                        </g>
                    )}
                </Group>
            </svg>

            {tooltipData && (
                <TooltipWithBounds
                    key={Math.random()}
                    top={margin.top + innerHeight}
                    left={tooltipLeft}
                    style={tooltipStyles}>
                    <>
                        <Typography
                            color={theme.palette.secondary.main}
                            fontSize={12}
                            fontWeight={600}
                            marginBottom={'8px'}>
                            {`Day ${tooltipData.x.toFixed(0)}`}
                        </Typography>

                        <Typography
                            color={theme.palette.secondary.light}
                            fontSize={12}
                            fontWeight={500}>
                            {`${Math.round(tooltipData.y)} bps `}
                        </Typography>
                    </>
                </TooltipWithBounds>
            )}
        </div>
    )
}
)