
import { RefreshError } from './RefreshError';


/**
 * Strategy:
 * --------- 
 * fetching resource and retrying once if the access token needed has expired.
 *
 * simple and brutal.
 *
 * a more sophisticated approach would be to store the expiry time/duration explicitly,
 * as it would save us one call expected to fail (when we know the token has expired).
 * however, that would imply incorporating this entire mechanism into a react context
 * or similar, because we would have to write the updated expiry back to a global
 * state of some sorts.
 * 
 * Race conditions: 
 * ----------------
 * Concurrent refresh calls lead to race conditions. 
 * This is a safty mechanism from the auth service 
 * (something is wrong if refresh tokens are requested concurrently - an attacker has stolen the refresh token)
 * However in the react app we do have this scenario as a component could make several concurrent api calls.
 * So we implement a custom locking mechanism, to ensure that 
 *      1. only one refresh token request is made at a time and 
 *      2. other requests wait until the refresh process completes.
 * 
 * Over tabs: 
 * ----------
 * The current implementation still allows for race conditions over tabs. 
 * We would need to implement navigator lock to get this done
 */

// Global variables to manage the refresh lock
let isRefreshing = false;
let refreshPromise = null;

// Function to refresh the token
async function refresh() {
    if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = new Promise(async (resolve, reject) => {
            try {
                const response = await fetch("/api/auth/refresh");
                isRefreshing = false;
                resolve(response);
            } catch (error) {
                isRefreshing = false;
                reject(error);
            }
        });
    }
    return refreshPromise;
}


export default async function fetchWithRefresh(url, options) {
    const doFetch = () => {
        return fetch(url, {
            ...options,
            credentials: 'same-origin',
        });
    };

    let response = await doFetch();

    if (response.status === 401) {
        const body = await response.clone().json();
        if (body.message === "Token expired") {
            try {
                const refresh_response = await refresh();
                if (!refresh_response.ok) {
                    throw new RefreshError("Token refresh failed");
                }
                return await doFetch();
            } catch (error) {
                throw new RefreshError("Token refresh failed");
            }
        }
    }
    return response;
}