import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import { Snack } from '../components/Snack'
import { useTheme } from '@mui/material/styles';
import { Dialog, Box, Typography } from '@mui/material';
import { DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DialogContent } from '@mui/material';
import SearchCompany from '../bond_report/company/SearchCompany';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { InputLabel, FormControl } from '@mui/material';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from "@mui/icons-material/Delete";
import fetchWithRefresh from '../helpers/api';


const deleteUser = async (id, setDbChanged, setOpenSnack, setSnackMessage) => {
    try {
        const response = await fetchWithRefresh('/api/auth/users/' + id,
            { method: 'DELETE' });
        const body = await response.json()
        if (response.ok) {
            setDbChanged(true)
            setOpenSnack(true)
            setSnackMessage({
                title: "Deleted user",
                message: body.message,
                severity: 'success'
            })
        } else {
            setOpenSnack(true)
            setSnackMessage({
                title: "Could not delete user",
                message: body.error,
                severity: 'error'
            })
        }
    } catch (error) {
        setOpenSnack(true)
        setSnackMessage({
            title: "Could not delete user",
            message: "Could not submit API call",
            severity: 'error'
        })
    }
}

const updateUser = async (id, credentials, setDbChanged, setOpenSnack, setSnackMessage) => {
    try {
        const response = await fetchWithRefresh('/api/auth/users/' + id,
            {
                method: 'PATCH',
                body: JSON.stringify(credentials),
                headers: { 'Content-Type': 'application/json' },
            });
        const body = await response.json()
        if (response.ok) {
            setOpenSnack(true)
            setSnackMessage({
                title: "Updated user",
                message: body.message,
                severity: 'success'
            })
            setDbChanged(true)
        } else {
            setOpenSnack(true)
            setSnackMessage({
                title: "Failed updating User",
                message: body.error,
                severity: 'error'
            })
        }
    } catch (error) {
        console.error("Error updating user", error)
        setOpenSnack(true)
        setSnackMessage({
            title: "Failed updating User",
            message: "Could not submit API call",
            severity: 'error'
        })
    }
}

const DeleteUser = (props) => {
    const { id, email, setDbChanged, setOpenSnack, setSnackMessage, setOpen } = props
    const theme = useTheme()
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const handleConfirmationClose = () => setConfirmationOpen(false);
    const handleConfirmationOpen = () => setConfirmationOpen(true)


    const handleDeleteUser = () => {
        deleteUser(id, setDbChanged, setOpenSnack, setSnackMessage)
        setConfirmationOpen(false)
        setOpen(false)
    }
    return (
        <>
            <Button
                autoFocus
                //onClick={handleDeleteUser}
                onClick={handleConfirmationOpen}
                sx={{ color: theme.palette.highlight.icon }}
                startIcon={<DeleteIcon />}
            >
                Delete User
            </Button>
            <Dialog open={confirmationOpen}
                onClose={handleConfirmationClose}
                scroll='paper'
                fullWidth={true}
                maxWidth='md'
            >

                <DialogTitle onClose={handleConfirmationClose} marginTop={'3%'}>
                    <Box>Are you sure you want to delete user {email}? </Box>
                </DialogTitle>
                <DialogContent>
                    <Box padding='16px' display="flex" justifyContent="space-between">

                        <Button
                            autoFocus
                            onClick={handleDeleteUser}
                            sx={{ color: theme.palette.highlight.icon }}>
                            Yes, delete permanently
                        </Button>
                        <Button
                            autoFocus
                            onClick={handleConfirmationClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default function EditUser({ id, setDbChanged, }) {


    const theme = useTheme()
    const roles = ['user', 'admin']
    const actives = ['true', 'false']
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState({ title: undefined, message: undefined, severity: undefined })
    const [companies, setCompanies] = useState([]);
    const [credentials, setCredentials] = useState({
        email: undefined
    })
    const [settings, setSettings] = useState({
        role: undefined,
        name: undefined,
        surname: undefined,
        company: undefined,
        active: undefined
    })


    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true)



    const handleInputChange = key => event => {
        setSettings({
            ...settings,
            [key]: event.target.value
        });
    }
    const handleCompany = (company) => {
        setSettings({
            ...settings,
            company: company
        })
    }

    const handleDeleteCompany = () => {
        setSettings({
            ...settings,
            company: undefined
        })
    }


    const handleUpdateUser = () => {
        updateUser(id, settings, setDbChanged, setOpenSnack, setSnackMessage)
        setOpen(false)
    }


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const parseUndefined = (s) => { return s === undefined ? '' : s }

    useEffect(() => {
        fetchWithRefresh('/api/bond_ranking/search/company')
            .then((resp) => resp.json())
            .then(setCompanies)
            .catch((error) => console.error('Error fetching companies', error))
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetchWithRefresh(`/api/auth/users/${id}`)
                const body = await response.json()
                setSettings({
                    company: body.company,
                    name: body.name,
                    surname: body.surname,
                    role: body.roles[0],
                    active: body.active
                })
                setCredentials(
                    {
                        email: body.email
                    }
                )
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
        fetchUser()
    }, [id, open]);

    return (
        <>
            <Button onClick={handleOpen}> Edit </Button>
            <Dialog open={open}
                onClose={handleClose}
                scroll='paper'
                fullWidth={true}
                maxWidth='md'
            >

                <DialogTitle onClose={handleClose} marginTop={'3%'}>
                    <Box justifyContent={'space-between'} display={'flex'}>
                        <Box>Edit User</Box>
                        <Typography fontSize={20} color={theme.palette.highlight.icon} paddingRight={'10%'}>
                            {credentials.email}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column',
                        gap: 4,
                        marginTop: '72px',
                        marginBottom: '72px',
                        marginRight: '10%',
                        marginLeft: '10%'
                    }}>
                        <TextField
                            id="name-edit"
                            label="Name"
                            value={parseUndefined(settings.name)}
                            onChange={handleInputChange('name')}
                        />
                        <TextField
                            id="surname-edit"
                            label="Surname"
                            value={parseUndefined(settings.surname)}
                            onChange={handleInputChange('surname')}
                        />
                        <SearchCompany
                            candidates={companies}
                            onSearchResultClick={handleCompany}
                            label={'Company'}
                            width={null} />


                        {settings.company &&
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}>
                                <Chip
                                    label={settings.company}
                                    display={'flex'}
                                    variant="filled"
                                    onDelete={handleDeleteCompany} />
                            </Stack>
                        }
                        <FormControl>
                            <InputLabel>Role</InputLabel>
                            <Select
                                label="Role"
                                id="role-edit"
                                name="role"
                                value={parseUndefined(settings.role)}
                                onChange={handleInputChange('role')}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Active</InputLabel>
                            <Select
                                label="Active"
                                id="active-edit"
                                name="active"
                                value={parseUndefined(settings.active)}
                                onChange={handleInputChange('active')}
                            >
                                {actives.map((active) => (
                                    <MenuItem key={active} value={active}>
                                        {active}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>

                <Box padding='16px' display="flex" justifyContent="space-between">
                    <Box>
                        <DeleteUser
                            id={id}
                            email={credentials.email}
                            setDbChanged={setDbChanged}
                            setOpenSnack={setOpenSnack}
                            setSnackMessage={setSnackMessage}
                            setOpen={setOpen} />
                    </Box>
                    <Box>
                        <Button
                            autoFocus
                            onClick={handleUpdateUser}
                            sx={{ color: theme.palette.highlight.icon }}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Snack
                open={openSnack}
                onClose={handleSnackClose}
                severity={snackMessage.severity}
                title={snackMessage.title}
                message={snackMessage.message}
            />
        </>
    )
}
