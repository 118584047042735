import React, { useState, useEffect } from "react";
import UserTable from "./UserTable";
import AddUser from "./AddUser";
import Box from "@mui/material/Box";
import { SectionTitle } from '../components/headers/SectionTitle';
import { DataStatus } from "./DataStatus";
import PageTitle from '../components/headers/PageTitle';
import fetchWithRefresh from "../helpers/api";
import useHandleError from '../helpers/handleError';


export default function Admin() {
    // todo: update table upon register and click
    const [dbChanged, setDbChanged] = useState(true)
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState(null)
    const handleError = useHandleError()

    // Todo: handle errors in child components as there are more async calls
    // Currently we are lazy and rely on the parent only. 
    

    useEffect(() => {
        fetchWithRefresh('/api/bond_ranking/search/company')
            .then((resp) => resp.json())
            .then(setCompanies)
            .catch((error) => {
                setError(error)
                console.log(error)})
    }, [])

    handleError(error)
    return (
        <div className='page-container'>
            <PageTitle title={'Backoffice'}/>
            <div className='row-container' style={{ marginBottom: '24px', gap:'50px' }}>
                <AddUser setDbChanged={setDbChanged} companies={companies} flex={'1 1 0'} />
                <DataStatus flex={'1 1 0'}/>
            </div>
            <SectionTitle title={'DebtRay Users'}/>
            <Box sx={{ mb: '60px' }}>
                <UserTable dbChanged={dbChanged} setDbChanged={setDbChanged}  />
            </Box>
        </div>

    );
}
