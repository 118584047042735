import React from 'react';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { Circle } from '@visx/shape';
import { scaleSqrt } from '@visx/scale';
import { extent } from '@visx/vendor/d3-array';
import { Label, Annotation, Connector } from '@visx/annotation';
import { useTheme } from '@mui/material/styles';


// layout
const margin = { top: 30, right: 10, bottom: 30, left: 10 };

export default function TimingCriticalLossPlot(props) {
    const { width, height, loss, gain } = props
    const theme = useTheme()
    const innerWidth = width - margin.left - margin.top
    const innerHeight = height - margin.top - margin.bottom
    const deltaX = innerWidth / 2

    const scaleRadius = scaleSqrt({
        range: [70, Math.min(deltaX * .5, innerHeight * .5)],
        domain: [Math.abs(loss), Math.abs(gain)].sort(),
        round: true
    }
    )

    const CircleWithText = (props) => {
        const { x, y, value, text, color = theme.palette.ranks[5] } = props
        const radius = scaleRadius(Math.abs(value))
        return (
            <>
                <Circle cx={x} cy={y} r={radius} fill={color} />
                <Text x={x} y={y}
                    verticalAnchor="middle"
                    textAnchor="middle"
                    fontFamily={theme.typography.fontFamily}
                    fill="white" >
                    {value.toLocaleString('en-US', { maximumFractionDigits: 0, useGrouping: true })}
                </Text>
                <Annotation
                    height={0}
                    width={0}
                    x={x}
                    y={y + radius + 5}
                    dx={0} // x offset of label from subject
                    dy={20} // y offset of label from subject

                >
                    <Connector type="line" />
                    <Label
                        fontFamily={theme.typography.fontFamily}
                        title={text}
                        showBackground={false}
                        showAnchorLine={false}
                        titleFontWeight={400}
                        width={200}
                    />
                </Annotation>
            </>
        )

    }


    return width < 10 ? null : (
        <svg width={width} height={height}>
                <CircleWithText
                    x={deltaX / 2}
                    y={innerHeight - scaleRadius(Math.abs(loss)) }
                    value={loss}
                    text={"Timing Losses for Issuers"} />

                <CircleWithText
                    x={3 / 2 * deltaX}
                    y={ innerHeight - scaleRadius(Math.abs(gain)) }
                    value={gain}
                    color={theme.palette.ranks[1]}
                    text={"Timing Gain for Issuers"} />
        </svg>
    )

}