import * as React from 'react';
import { useEffect, useState } from "react";
import { Box } from '@mui/material';
import { useSharedIsin } from '../../contexts/SharedIsin';
import SearchBank from './SearchBank';
import PageTitle from '../../components/headers/PageTitle';
import { useNavigate } from "react-router-dom";
import { IdTitle } from '../../components/headers/IdTitle';
import BankTable from './BankTable';
import fetchWithRefresh from '../../helpers/api';
import useHandleError from '../../helpers/handleError';



export default function Bank() {
    const { isin, setIsin } = useSharedIsin()
    const [data, setData] = useState(undefined)
    const [searchCandidates, setSearchCandidates] = useState([])
    const [syndicate, setSyndicate] = useState(undefined);
    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const handleError = useHandleError()


    const handleRowClick = (isin) => {
        setIsin(isin)
        navigate("/bond/bond-ranking")
    }


    useEffect(() => {
        fetchWithRefresh('/api/bond_ranking/search/syndicate')
            .then((resp) => resp.json())
            .then(setSearchCandidates)
            .catch((error) => {
                console.log("Fetch error bank search", error)
                setError(error)
            })
    }, [])


    useEffect(() => {
        if (typeof syndicate !== 'undefined') {
            fetchWithRefresh(`/api/bond_ranking/syndicate/?syndicate=${encodeURIComponent(syndicate)}`)
                .then((r) => r.json())
                .then(setData)
                .catch((error) => {
                    console.log("Fetch error bank", error)
                    setError(error)
                })
        }
    }, [syndicate])

    handleError(error)
    return (
        <div className='page-container' style={{ marginBottom: '72px' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <PageTitle title={'BankRanking'} />
                {searchCandidates &&
                    <SearchBank candidates={searchCandidates} onSearchResultClick={setSyndicate} />
                }
            </Box>
            {data &&
                <>
                    <IdTitle id={data.id} />
                    <BankTable data={data.data} onRowClick={handleRowClick} />
                </>
            }
        </div>
    )
}