import React, { useState } from 'react';
import { InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { getSettingsFromCookie } from '../helpers/cookies';


const inputProps = { sx: { borderRadius: '8px' } }

const sx = {
    mb: '30px',
    '&.Mui-focused': {
        color: '#1A3C7F',
        transform: 'none',
        transformOrigin: 'none',
    },
}

function LoginForm({ setSettings }) {
    // Todo: remove snackbar!
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorOccurred, setErrorOccured] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    const handleUsernameChange = (event) => {
        setEmail(event.target.value);
        setErrorOccured(false);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrorOccured(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        setLoading(true)

        try {
            const response = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            // TODO - check http status code!
            //  absence of error is not a sufficient indicator

            setSettings(getSettingsFromCookie())

            // Display success Snackbar
            setSnackbarMessage('Login successful');
            setSnackbarOpen(true);

            // Clear the form
            setEmail('');
            setPassword('');

            // Navigate to home
            navigate('/');
        } catch (error) {
            // Display error Snackbar
            //setSettings({isLoggedIn: false})
            console.log(error)
            setSnackbarMessage('Login failed');
            setSnackbarOpen(true);
            setErrorOccured(true)
        }
        finally {
            setLoading(false)
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <TextField
                sx={sx}
                id="login_email"
                label="Email"
                value={email}
                onChange={handleUsernameChange}
                fullWidth
                InputProps={inputProps}
            />
            <TextField id="login_password"
                sx={sx}
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    ...inputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {errorOccurred &&
                <Typography fontSize='14px' color='#F04438' marginBottom='15px'>
                    Invalid credentials. Please try again.
                </Typography>
            }
            <Button
                className="button"
                variant='contained'
                fullWidth
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                sx={{ mb: '30px' }}>
                Continue
            </Button>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </form>
    );
}


const RequestDemo = () => {
    const handleButtonClick = () => {
        const email = 'mailto:info@debtray.com';
        // Open default email client
        window.location.href = email;
    }
    return (
        <>
            <div className="line-container">
                <div className="line"></div>
                <Typography padding='8px' fontSize='18px'>OR</Typography>
                <div className="line"></div>

            </div>
            <Button
                sx={{ marginTop: '32px', marginBottom: '32px' }}
                className="button-highlight"
                variant='contained'
                fullWidth
                onClick={handleButtonClick}>
                Request a demo
            </Button>
        </>
    )
}

const TermsOfUse = () => {
    const theme = useTheme()
    return (
        <Box display='flex' justifyContent='center'>
            <Typography fontSize={'12px'} fontWeight={400} color={theme.palette.secondary.light}>
                Proceeding with login means that you agree with our &nbsp;
            </Typography>

            <Typography fontSize={'12px'} fontWeight={600} color={theme.palette.primary[700]}>
                <a href='https://debtray.com/terms' rel="noopener noreferrer">
                    Terms of use.
                </a>
            </Typography>
        </Box>

    )
}

export default function Login({ setSettings }) {
    return (
        <>
            <div className='login-page'>
                <Box padding='60px' alignSelf='start'>
                    <img src="/public/logos/logo_dark_mode.svg" alt="DebtRay" className="logo" />
                </Box>
                <Box className='login-container'>
                    <Typography fontSize='36px' fontWeight='600' paddingBottom='32px'>Sign In</Typography>
                    <LoginForm setSettings={setSettings} />
                    <RequestDemo />
                    <TermsOfUse />
                </Box>
            </div>

        </>
    );
}

