import * as React from "react";
import { useState } from "react";
import { Dialog, DialogTitle, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export default function Info(props) {
    const { title, info, fontSize='inherit', mt } = props
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const iconStyle = {
        color: theme.palette.highlight.main,
        //padding: '0px',
        mt: mt ? mt : fontSize === 'medium' ? '-5px' :  '0px',
        fontSize: fontSize,
    }

    return (
        <>
            <IconButton onClick={handleOpen}>
                <InfoOutlinedIcon sx={iconStyle} />
            </IconButton>
            <Dialog open={open} onClose={handleClose} scroll='paper'>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <Box sx={{ p: 3 }}>
                    {info}
                </Box>
            </Dialog>
        </>
    )
}
