import * as React from "react";
import { useEffect, useState } from "react";
import FilterLeague from "./FilterLeague";
import { useSharedIsin } from "../../contexts/SharedIsin";
import { useNavigate } from "react-router-dom";
import FilterSummary from './FilterSummary';
import PageTitle from '../../components/headers/PageTitle';
import { Statistics } from './Statistics';
import Bonds from "./Bonds";
import dayjs from "dayjs";
import fetchWithRefresh from "../../helpers/api";
import useHandleError from '../../helpers/handleError';
import { useLeagueFilter } from '../../contexts/Filters'

export const getLeagueDefaults = (limits) => {
    return {
        minAmountIssued: limits?.minAmountIssued || undefined,
        maxAmountIssued: limits?.maxAmountIssued || undefined,
        minFirstAnnouncementDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        maxFirstAnnouncementDate: limits?.maxFirstAnnouncementDate || undefined,
        minLifeSpan: limits?.minLifeSpan || undefined,
        maxLifeSpan: limits?.maxLifeSpan || undefined,
        minOriginalSpread: limits?.minOriginalSpread || undefined,
        maxOriginalSpread: limits?.maxOriginalSpread || undefined,
        industry: undefined,
    }
}



export default function ExploreBonds({limits}) {
    const navigate = useNavigate()
    const { isin, setIsin } = useSharedIsin()
    const [leagueData, setLeagueData] = useState(undefined)

    // bond league search parameters for market insights
    const {
        industry, setIndustry,
        minAmountIssued, setMinAmountIssued,
        maxAmountIssued, setMaxAmountIssued,
        minFirstAnnouncementDate, setMinFirstAnnouncementDate,
        maxFirstAnnouncementDate, setMaxFirstAnnouncementDate,
        minLifeSpan, setMinLifeSpan,
        maxLifeSpan, setMaxLifeSpan,
        minOriginalSpread, setMinOriginalSpread,
        maxOriginalSpread, setMaxOriginalSpread
    } = useLeagueFilter();


    const [error, setError] = useState(null)
    const handleError = useHandleError()

    const leagueQuery = () => {
        const params = new URLSearchParams()
        if (industry) {
            params.append('industry', industry.toString())
        }
        if (minAmountIssued) {
            params.append('min_amount_issued', minAmountIssued.toString())
        }
        if (maxAmountIssued) {
            params.append('max_amount_issued', maxAmountIssued.toString())
        }
        if (minFirstAnnouncementDate) {
            params.append('min_first_announcement_date', minFirstAnnouncementDate.toString())
        }
        if (maxFirstAnnouncementDate) {
            params.append('max_first_announcement_date', maxFirstAnnouncementDate.toString())
        }
        if (minLifeSpan) {
            params.append('min_life_span', minLifeSpan.toString())
        }
        if (maxLifeSpan) {
            params.append('max_life_span', maxLifeSpan.toString())
        }
        if (minOriginalSpread) {
            params.append('min_original_spread', minOriginalSpread.toString())
        }
        if (maxOriginalSpread) {
            params.append('max_original_spread', maxOriginalSpread.toString())
        }
        return `/api/bond_ranking/league?${params.toString()}`
    }


    // fetch bond league data
    const leagueUrl = leagueQuery()
    useEffect(() => {
        fetchWithRefresh(leagueUrl)
            .then((r) => r.json())
            .then(setLeagueData)
            .catch((error) => {
                setError(error)
                console.log(error)
            })
    }, [leagueUrl])


    const leagueListeners = {
        setIndustry: setIndustry,
        setMinAmountIssued: setMinAmountIssued,
        setMaxAmountIssued: setMaxAmountIssued,
        setMinFirstAnnouncementDate: setMinFirstAnnouncementDate,
        setMaxFirstAnnouncementDate: setMaxFirstAnnouncementDate,
        setMinLifeSpan: setMinLifeSpan,
        setMaxLifeSpan: setMaxLifeSpan,
        setMinOriginalSpread: setMinOriginalSpread,
        setMaxOriginalSpread: setMaxOriginalSpread
    }

    const leagueFilters = {
        industry: industry,
        minAmountIssued: minAmountIssued,
        maxAmountIssued: maxAmountIssued,
        minFirstAnnouncementDate: minFirstAnnouncementDate,
        maxFirstAnnouncementDate: maxFirstAnnouncementDate,
        minLifeSpan: minLifeSpan,
        maxLifeSpan: maxLifeSpan,
        minOriginalSpread: minOriginalSpread,
        maxOriginalSpread: maxOriginalSpread,

    }
    const handleClick = (isin) => {
        setIsin(isin)
        navigate("/bond/bond-ranking")
    }

    handleError(error)

    if (limits && leagueData) {

        // todo specify this more
        return (
            <>
                <div className="page-container">
                    <PageTitle
                        title={'BondExplorer'}
                        icon={<img src="/public/icons/activity.svg" alt="Activity" />} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FilterLeague 
                            selection={leagueFilters}
                            listeners={leagueListeners}
                            limits={limits} />
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'flex-end', paddingTop: '12px', paddingBottom: '24px',
                        gap: '8px'
                    }}>
                        <FilterSummary data={leagueData} />
                    </div>
                    <Bonds data={leagueData} onRowClick={handleClick} />
                    <Statistics data={leagueData} onRowClick={handleClick} />
                </div>
            </>
        )

    }
}