import React from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Arc, Circle } from '@visx/shape';
import { GridRadial } from '@visx/grid';
import { Text } from '@visx/text';
import { useTheme } from '@mui/material/styles';



const thresholds = [0, 0.075, 0.2, 0.4, 0.5, 0.6, 0.7, 0.875, 0.95, 1]
const rankThresholds = [
    { rank: 5, low: thresholds[0], high: thresholds[1] },
    { rank: 4, low: thresholds[1], high: thresholds[2] },
    { rank: 3, low: thresholds[2], high: thresholds[3] },
    { rank: 2, low: thresholds[3], high: thresholds[4] },
    { rank: 1, low: thresholds[4], high: thresholds[5] },
    { rank: 2, low: thresholds[5], high: thresholds[6] },
    { rank: 3, low: thresholds[6], high: thresholds[7] },
    { rank: 4, low: thresholds[7], high: thresholds[8] },
    { rank: 5, low: thresholds[8], high: thresholds[9] },]

// layout 
const padding = 20

export default function PricingCriticalLossPlot(props) {
    const { width, height, highlightRadical, amount, title} = props
    const highlightIndices = highlightRadical === 'left' ? [0,1] : [7,8]
    const theme = useTheme()
    const centerY = height / 2
    const centerX = width / 2

    // percentile range in fraction - NOT percentage
    const xScale = scaleLinear({
        domain: [0, 1],
        range: [-Math.PI / 4 * 3, Math.PI / 4 * 3],
    })

    // radius scale could correspond to issuer loss 
    const yScale = scaleLinear({
        domain: [0, 1],
        range: [0, centerY - 2*padding]
    });

    const angle = (x) => xScale(x)
    const radius = (y) => yScale(y)   

    if (width < 10) return null;
    return (
        <div>
            <svg width={width} height={height}>
                <Group left={centerX} top={centerY} >
                    {/* Pies for the ranks */}
                    {rankThresholds.map((rank, i) => {
                        const outerRadius = highlightIndices.includes(i) ? radius(1.15) : radius(1)
                        const innerRadius = radius(.5)
                        return (
                            <Arc
                                key={`arc-${i}`}
                                startAngle={angle(rank.low)}
                                endAngle={angle(rank.high)}
                                outerRadius={outerRadius}
                                innerRadius={innerRadius}
                                padAngle={0.02}
                                cornerRadius={3}
                                fill={theme.palette.ranks[rank.rank]}
                            />)
                    })
                    }


                    {/* Radial grid lines */}
                    <GridRadial
                        scale={yScale}
                        tickValues={[.5, .5 + .5/3, .5 + 1/3, .5 + 1.5/3]}
                        stroke='black'
                        strokeWidth={1}
                        fill='transparent'
                        strokeOpacity={0.1}
                    />
                    <Text
                    key={"text-unit"}
                    x={0}
                    y={yScale(1.1)}
                    dy={10}
                    fontFamily={theme.typography.fontFamily}
                    textAnchor='middle'
                    verticalAnchor='start'
                    fill='black'
                    >
                    {title}
                </Text>
                <Text
                    key={"text-amount"}
                    x={0}
                    y={yScale(0)}
                    dy={10}
                    fontFamily={theme.typography.fontFamily}
                    textAnchor='middle'
                    verticalAnchor='center'
                    fill='black'
                    scaleToFit>
                    {amount.toLocaleString('en-US', { maximumFractionDigits: 0, useGrouping: true })}
                </Text>
                </Group>
            </svg>
        </div>
    )

}