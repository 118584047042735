import { createTheme } from '@mui/material/styles';


const palette = {
  primary: {
    100: "rgba(208, 217, 234, 1)",
    50: '#E1E7F1',
    200: '#8FA4CC',
    300: '#6A85BA',
    500: '#1F4898',
    600: '#1A3C7F',
    700: '#153065',
  },
  secondary: {
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD', 
    400: '#98A2B3', 
    500: '#667085', 
    600: '#475467', 
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
    veryLight: '#F2F4F7',
    light: '#667085',
    main: '#475467',
    dark: '#344054',
  },

  highlight: {
    main: '#D1A617',
    icon: '#AE8A13'
  },

  ranks: {
    1: '#039855',
    2: '#6CE9A6',
    3: '#FEDF89',
    4: '#FDA29B',
    5: '#F04438'
  }
}

const typography = {
  fontFamily: 'Manrope, sans-serif',
  button: {
    textTransform: "none",
  },
}

const MuiDataGrid = {
  styleOverrides: {
    root: {
      button: {
        color: palette.highlight.main,
      },
    },
    columnHeader: {
      backgroundColor: '#F9FAFB',
    },
    columnSeparator: {
      display: 'none'
    },

    columnHeaderTitle: {
      fontWeight: 'bold',
    },

  },
}

const MuiButton = {
  styleOverrides: {
    root: {
      borderRadius: 8,
    },
    outlined: {
      borderColor: '#D0D5DD', 
      '&:hover': {
        borderColor: '#D0D5DD', 
      },
    },
  },
  defaultProps: {
    variant: 'outlined'
  },
}

const MuiToggleButton = {
  styleOverrides: {
    root: {
      borderRadius: 8,
    },
    outlined: {
      borderColor: '#D0D5DD', 
      '&:hover': {
        borderColor: '#D0D5DD', 
      },
    },
  },
  defaultProps: {
    variant: 'outlined'
  },
}



const theme = createTheme({
  palette: palette,
  typography: typography,

  components: {
    MuiDataGrid: MuiDataGrid,
    MuiButton: MuiButton,
    MuiToggleButton: MuiToggleButton,
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,  // Set the default font size for icons
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
      },
    },
  },
});

export default theme;