import * as React from "react";
import { useState } from "react";
import { ParentSize } from '@visx/responsive';
import PricingGaugePlot from "../../components/plots/PricingGaugePlotPercentileScale";
import Typography from "@mui/material/Typography";
import BoxTitle from '../../components/headers/BoxTitle';
import GraphBox from '../../components/GraphBox';
import { Box, ToggleButton, ToggleButtonGroup, TextField, IconButton } from '@mui/material';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import MulitpleTimeSeriesLinePlot from "../../components/plots/MulitpleTimeSeriesLinePlot";
import SingleTimeSeriesLinePlot from "../../components/plots/SingleTimeSeriesLinePlot";
import HorizontalLine from '../../components/HorizontalLine';
import LabelCircle from '../../components/LabelCircle';
import CircleWithRank from "../../components/CircleWithRank";
import { nullToUndefined, roundWithNull, nullToUndefinedArray } from '../../helpers/handleNull';
import { mutualMax, mutualMin, normalizeArray } from "../../helpers/arrayHelpers";
import { SectionTitle } from "../../components/headers/SectionTitle";
import { InfoTable } from '../../components/tables/InfoTable';
import Info from '../../components/Info';
import { bondInfo } from "./InfoTexts";
import PricingGaugePlotSimulator from '../../components/plots/PricingGaugePlotSimulator';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from "@emotion/react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';




export function PricingSummary({ data, flex }) {
    const rows = [
        {
            key: 'Original Spread',
            value: `${roundWithNull(data.originalSpread)} bps`
        },
        {
            key: 'Delta Spread',
            value: `${roundWithNull(data.deltaSpread)} bps`,
            info: bondInfo.deltaSpread
        },
        {
            key: 'Spread Development',
            value: `${roundWithNull(nullToUndefined(data.relativeDeltaSpread) * 100, 1)} %`,
            info: bondInfo.spreadDevelopment

        },
        {
            key: `Pricing ${data.pricingLossForIssuer < 0 ? 'Loss' : 'Gain'} for Issuer`,
            value: `${roundWithNull(data.pricingLossForIssuer)} m ${data.principalCurrency}`,
            info: bondInfo.pricingLossForIssuer
        },
    ]
    return (
        <GraphBox paddingBottom='16px' flex={flex}>
            <BoxTitle
                title={'Pricing Statistics'}
                icon={<img src="/public/icons/coins-line.svg" alt="Price" />}
                paddingBottom="16px" />
            <InfoTable rows={rows} />
        </GraphBox>
    );
}


const TimeSeries = (props) => {
    // Maybe its better not to show the error bars: bond itself part of the stats. 
    const { x, y, yRange, title, flex, info, height, withNormalizationToggle = true } = props
    const [normalize, setNormalize] = useState(true)
    const handleChange = (event) => { setNormalize(event.target.checked) }

    const preProcess = (xs) => {
        if (normalize) {
            return normalizeArray(nullToUndefinedArray(xs))
        } else {
            return (nullToUndefinedArray(xs))
        }
    }

    return (
        <GraphBox flex={flex} height={height}>
            <Box display="flex" justifyContent="space-between" paddingBottom={"20px"}>
                <Box display="flex" alignItems="flex-start">
                    <BoxTitle title={title} />
                    {info && <Info title={title} info={info} />}
                </Box>
                {withNormalizationToggle ?
                    <FormControlLabel
                        control={<Switch className="switch" checked={normalize} onChange={handleChange} />}
                        label='Normalize'
                    />
                    : null}
            </Box>
            <div style={{ minHeight: 0, flex: '1' }}>
                <ParentSize>
                    {({ width, height }) => <SingleTimeSeriesLinePlot
                        width={width}
                        height={height}
                        x={x}
                        y={preProcess(y)}
                        yRange={yRange}
                    />}
                </ParentSize>
            </div>
        </GraphBox>
    )
}

const TimeSeriesPerformanceComparision = (props) => {
    const { data, height } = props
    const normalize = (ys) => { return normalizeArray(nullToUndefinedArray(ys)) }
    const spread_peers = normalize(data.spreadRelativeToPeers)
    const spread_benchmark = normalize(data.spreadRelativeToBenchmark)
    const yRange = [mutualMin(spread_peers, spread_benchmark), mutualMax(spread_peers, spread_benchmark)]
    return (
        <>
            <TimeSeries
                title='Spread to Benchmark Early Secondary Market'
                x={data.pricingDeltaDays}
                y={spread_benchmark}
                yRange={yRange}
                withNormalizationToggle={false}
                info={bondInfo.spreadToBenchmarkEarlySecondaryMarket}
                height={height}
                flex={'1 1 0'} />
            <TimeSeries
                title='Spread to Peers Early Secondary Market'
                x={data.pricingDeltaDays}
                y={spread_peers}
                yRange={yRange}
                withNormalizationToggle={false}
                info={bondInfo.spreadToPeersEarlySecondaryMarket}
                height={height}
                flex={'1 1 0'} />
        </>
    )

}

export function MulitpleTimeSeries(props) {
    const { data, title, info, flex, height } = props

    const [normalize, setNormalize] = useState(true)
    const [showBond, setShowBond] = useState(true)
    const [showPeers, setShowPeers] = useState(true)
    const [showBenchmark, setShowBenchmark] = useState(true)

    const handleNormalizationToggle = (event) => {
        setNormalize(event.target.checked)
    }

    const handleBondToggle = () => {
        setShowBond(!showBond)
    }

    const handlePeersToggle = () => {
        setShowPeers(!showPeers)
    }
    const handleBenchmarkToggle = () => {
        setShowBenchmark(!showBenchmark)
    }

    const colors = {
        bond: 'white',
        peers: '#FECDCA',
        benchmark: '#FEDF89'
    }


    const DisplayCharts = () => {
        return (
            <ToggleButtonGroup aria-label='display-filters' size="medium">
                <ToggleButton
                    value='bond'
                    aria-label='show bond'
                    onClick={handleBondToggle}
                    selected={showBond}>
                    <LabelCircle color={colors.bond} /> Bond
                </ToggleButton>
                <ToggleButton
                    value='peers'
                    aria-label='show peers'
                    onClick={handlePeersToggle}
                    selected={showPeers}>
                    <LabelCircle color={colors.peers} /> Peers
                </ToggleButton>
                <ToggleButton
                    value='benchmark'
                    aria-label='show benchmark'
                    onClick={handleBenchmarkToggle}
                    selected={showBenchmark}>
                    <LabelCircle color={colors.benchmark} /> Benchmark
                </ToggleButton>
            </ToggleButtonGroup>
        )
    }

    const preProcess = (xs) => {
        if (normalize) {
            return normalizeArray(nullToUndefinedArray(xs))
        } else {
            return (nullToUndefinedArray(xs))
        }
    }

    const x = {
        xLabel: 'Days from issuance',
        xUnit: 'Days',
        x: data.pricingDeltaDays
    }
    const bond = {
        show: showBond,
        yLabel: data.issuer,
        yUnit: 'bps',
        y: preProcess(data.closingSpread),
        color: colors.bond,
        strokeWidth: 3
    }

    const peers = {
        show: showPeers,
        yLabel: 'Peers',
        yUnit: 'bps',
        y: preProcess(data.adjustedPeerSpreadMedian),
        //yErrorLow: normalize ? data.adjustedPeerSpreadLow.map(y=> y - y0) : data.adjustedPeerSpreadLow, 
        //yErrorHigh: normalize ? data.adjustedPeerSpreadHigh.map(y=> y - y0) : data.adjustedPeerSpreadHigh,
        color: colors.peers,
        strokeWidth: 3
    }


    const benchmark = {
        show: showBenchmark,
        yLabel: 'Benchmark',
        yUnit: 'bps',
        y: preProcess(data.benchmarkSpread),
        color: colors.benchmark,
        strokeWidth: 3

    }

    return (
        <GraphBox flex={flex} height={height}>
            <Box display="flex" justifyContent="space-between" paddingBottom={"20px"}>
                <Box display="flex" alignItems="flex-start">
                    <BoxTitle title={title} />
                    {info && <Info title={title} info={info} mt='5px' />}
                </Box>
                <DisplayCharts />
                <FormControlLabel
                    control={<Switch className="switch" checked={normalize} onChange={handleNormalizationToggle} />}
                    label='Normalize'
                />
            </Box>
            <HorizontalLine />
            <div style={{ minHeight: 0, flex: '1' }}>
                <ParentSize>
                    {({ width, height }) => <MulitpleTimeSeriesLinePlot
                        width={width}
                        height={height}
                        x={x}
                        ys={[bond, peers, benchmark]}
                    />}
                </ParentSize>
            </div>
        </GraphBox>
    );
}



const judgePricing = (rank, deltaSpread, percentile) => {
    if (rank === 5 && percentile < .55 && deltaSpread < 0.) {
        return "Strong overpaying by issuer";
    }
    else if (rank === 4 && percentile < .55 && deltaSpread < 0.) {
        return "Overpaying by issuer";
    }
    else if (rank === 3 && percentile < .55) {
        return "Slight advantage for investor"
    }
    else if (rank === 2 && percentile < .55) {
        return "Good balance between issuer and investor"
    }
    else if (rank === 1) {
        return "Perfect pricing for issuer"
    }
    else if (rank === 2 && percentile > .55) {
        return "Good pricing for issuer"
    }
    else if (rank === 3 && percentile > .55) {
        return "Slight disadvantage for investor"
    }
    else if (rank === 4 && percentile > .55 && deltaSpread >= 0) {
        return "Undercompensation of investor"
    }
    else if (rank === 5 && percentile > .55 && deltaSpread >= 0) {
        return "Strong undercompensation of investor"
    }

}

export function PricingGauge(props) {
    const { data, info, width, height, flex } = props
    const title = 'Pricing Summary'
    const judgement = judgePricing(data.data.pricingRank, data.data.deltaSpread, data.data.pricingPercentile)
    return (
        <GraphBox width={width} height={height} flex={flex}>
            <Box display="flex" alignItems="flex-start">
                <BoxTitle title={title} />
                {info && <Info title={title} info={info} />}
            </Box>
            <div style={{ minHeight: 0, flex: '1' }}>
                <ParentSize>
                    {({ width, height }) => <PricingGaugePlot
                        data={data}
                        width={width}
                        height={height} />}
                </ParentSize>
            </div>
            <Box display='flex' justifyContent='center'>
                <Typography fontSize="16px" fontWeight='600' color='#101828'>
                    {judgement}
                </Typography>
            </Box>
        </GraphBox>

    )
}

export function PricingSimulator(props) {
    const { data, info, width, height, flex } = props
    const theme = useTheme()
    const title = 'Pricing Simulator'
    const actualLoss = data.data.pricingLossForIssuer.toFixed(1)
    const [simulatedSpread, setSimulatedSpread] = useState(data.data.originalSpread);

    const simulateLoss = () => {
        // this is the easiest formular and should be replaced lateron
        const a = data.data.amountIssued
        const l = data.data.lifeSpan
        const spread = data.data.originalSpread
        const loss = data.data.pricingLossForIssuer
        const average = data.data.pricingRestrictedPeerAverageRelativeDeltaSpread
        const simulatedLoss = loss + (spread - simulatedSpread) * (1 - average) * a * l / 10000
        return simulatedLoss.toFixed(1)
    }

    const simulatedLoss = simulateLoss()

    const ResetSimulation = () => {
        const theme = useTheme()
        const onResetClick = () => { setSimulatedSpread(data.data.originalSpread) }
        return (
            <IconButton
                onClick={onResetClick}>
                <RestartAltIcon
                    sx={{ color: theme.palette.highlight.main }} />
            </IconButton>
        )
    }

    const onMinusClick = () => { setSimulatedSpread((simulatedSpread - 1)) }
    const onPlusClick = () => { setSimulatedSpread(simulatedSpread + 1) }
    const handleTextFieldChange = (event) => { setSimulatedSpread(event.target.value) }

    return (
        <GraphBox width={width} height={height} flex={flex}>
            <Box display="flex" justifyContent="flex-start" gap="110px">
                <Box display="flex" alignItems="flex-start">
                    <BoxTitle title={title} />
                    {info && <Info title={title} info={info} />}
                </Box>
                <Box display="flex">
                    {/* Simulation input. 
                    It would be cleaner to put this into its own komponent. 
                    But then the textfield looses focus as this is re-rendered every input change
                    On could make a component and drag it outside the Parent Component. 
                    This would require to pass quite some variables. 
                    */}
                    <>
                        <IconButton
                            onClick={onPlusClick}>
                            <AddCircleOutlineIcon sx={{ color: theme.palette.highlight.main }} />
                        </IconButton>
                        <TextField
                            sx={{ width: '80px', textAlign: 'right' }}
                            hiddenLabel
                            id="original-spread-simulator-input"
                            value={simulatedSpread}
                            variant="outlined"
                            size="small"
                            onChange={handleTextFieldChange}
                        />
                        <IconButton
                            onClick={onMinusClick}>
                            <RemoveCircleOutlineIcon sx={{ color: theme.palette.highlight.main }} />
                        </IconButton>
                    </>
                    <ResetSimulation />
                </Box>
            </Box>

            <div style={{ minHeight: 0, flex: '1' }}>
                <ParentSize>
                    {({ width, height }) => <PricingGaugePlotSimulator
                        targetOriginalSpread={simulatedSpread}
                        data={data}
                        width={width}
                        height={height} />}
                </ParentSize>
            </div>
            <Box display='flex' justifyContent='center'>
                <Typography fontSize="16px" color='#101828'>
                    <b>{`Simulated ${simulatedLoss < 0 ? 'Loss' : 'Gain'}: ${simulatedLoss} m Euro`}</b>
                    ({`Actual ${actualLoss < 0 ? 'Loss' : 'Gain'}: ${actualLoss} m Euro`})
                </Typography>
            </Box>
        </GraphBox>

    )

}


export default function PricingOverview({ data }) {
    const rankingExists = data.data.pricingRank != null
    return (
        <>
            <SectionTitle title={'Pricing Analysis'} />
            <div className='row-container' style={{ marginBottom: '24px' }}>
                <PricingSummary
                    data={data.data}
                    flex={' 1 1 0'}
                />
                <div style={{ flex: '.5 1 0' }}>
                    <CircleWithRank rank={data.data.pricingRank} label={'PricingRank'} diameter={124} />
                </div>
                <div style={{ flex: '1.5 1 0' }}></div>

            </div>

            {rankingExists ?
                <>
                    <div className='row-container' style={{ marginBottom: '24px' }}>
                        <MulitpleTimeSeries
                            data={data.data}
                            title={'Spread Early Secondary Market'}
                            info={bondInfo.spreadEarlySecondaryMarket}
                            height={'500px'} />
                    </div>
                    <div className='row-container' style={{ marginBottom: '24px' }}>
                        <TimeSeriesPerformanceComparision
                            data={data.data}
                            height='400px'
                        />
                    </div>
                    <div className='row-container' style={{ marginBottom: '24px' }}>
                        <PricingGauge
                            data={data}
                            info={bondInfo.debtRayPriceRankingSummary}
                            height={'500px'}
                            flex={'1 1 0'} />
                        <PricingSimulator
                            key={data.data.isin}
                            data={data}
                            info={null}
                            height={'500px'}
                            flex={'1 1 0'} />
                    </div>
                </> :
                <Box display='flex' alignContent='center' justifyContent='center'>
                    <p>No further pricing analysis available</p>
                </Box>
            }
        </>)
}